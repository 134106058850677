import PrimaryMenuItemLink from './PrimaryMenuItemLink';
import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { IMenu } from 'src/services/api/types/IMenu';
import { listToTree } from 'src/utils/listToTree';
import Container from '@components/containers/Container';
import MegaMenu from '../MegaMenu';
import { MenuContext } from '../context/Menu.context';
import { handleOpenMenu } from 'src/components/views/Menu/utils/utils';
import useWindowSize from 'src/hooks/useWindowSize';
import debounce from 'lodash/debounce';

export interface PrimaryMenuProps {
  items: IMenu[];
}

const PrimaryMenu: React.FunctionComponent<PrimaryMenuProps> = ({ items }) => {
  const { width } = useWindowSize();
  const isDesktop = width && +width > 1200;

  const itemsTree = useMemo(() => listToTree(items), [items]);
  const { openedMenu, openMegaMenuHandler, closeMegaMenuHandler } =
    useContext(MenuContext);
  const xlClasses = `xl:flex-row`;

  const debouncedHandleMouseEnter = debounce(
    (menuItem) =>
      handleOpenMenu(menuItem.mega_menu_id, openedMenu, openMegaMenuHandler),
    200,
  );

  return (
    <div>
      <ul className={`flex flex-col ${xlClasses}`}>
        {itemsTree.map((menuItem, index) => {
          const hasChildren = menuItem.children && menuItem.children.length > 0;

          const hasBlocks = !!(
            Array.isArray(menuItem.mega_menu_blocks) &&
            menuItem.mega_menu_blocks.length > 0
          );

          return (
            <li
              key={menuItem.ID}
              className={clsx('mb-20 lg:mx-32 xl:mb-0 xl:mx-0', {
                [menuItem.classes.join(' ')]: menuItem.children,
                'xl:mr-32': index < itemsTree.length - 1,
              })}
              {...((hasChildren || hasBlocks) && {
                onMouseEnter: () => {
                  if (isDesktop) {
                    if (!openedMenu) {
                      debouncedHandleMouseEnter(menuItem);
                    } else {
                      handleOpenMenu(
                        menuItem.mega_menu_id,
                        openedMenu,
                        openMegaMenuHandler,
                      );
                    }
                  }
                },

                onMouseLeave: () => debouncedHandleMouseEnter.cancel(),

                onClick: () => {
                  handleOpenMenu(
                    menuItem.mega_menu_id,
                    openedMenu,
                    openMegaMenuHandler,
                    closeMegaMenuHandler,
                  );
                },
              })}
            >
              <PrimaryMenuItemLink
                menuItem={menuItem}
                hasChildren={hasChildren || hasBlocks}
              />

              {hasBlocks && <MegaMenu menuItem={menuItem} />}

              {hasChildren && renderGeneralSubMenu(menuItem)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

// LEVEL 1
const renderGeneralSubMenu = (items: IMenu) => {
  const { children } = items;
  const xlClasses = `xl:shadow-xl xl:absolute bottom-0 left-0 xl:translate-y-full xl:max-h-screen xl:overflow-auto`;

  return (
    <ul
      className={`hidden invisible transition-all border-t bg-neutral-0 lg:p-32 w-full z-10 pb-80 ${xlClasses}`}
    >
      <Container>
        {children.map((menuItem) => {
          const hasChildren = menuItem.children && menuItem.children.length > 0;
          return (
            <li
              key={menuItem.ID}
              className={clsx('relative', {
                [menuItem.classes.join(' ')]: menuItem.children,
              })}
            >
              <PrimaryMenuItemLink
                menuItem={menuItem}
                hasChildren={hasChildren}
              />
              {hasChildren && renderSubMenu(menuItem.children)}
            </li>
          );
        })}
      </Container>
    </ul>
  );
};

//LEVEL 2
const renderSubMenu = (items: IMenu['children']) => {
  return (
    <ul className="transition-all border  absolute top-full bg-neutral-0 py-8 px-12 w-168 z-10">
      {items.map((menuItem) => {
        const hasChildren = menuItem.children && menuItem.children.length > 0;
        return (
          <li
            key={menuItem.ID}
            className={clsx({
              [menuItem.classes.join(' ')]: menuItem.children,
            })}
          >
            <PrimaryMenuItemLink
              menuItem={menuItem}
              hasChildren={hasChildren}
            />
            {hasChildren && renderSubMenu(menuItem.children)}
          </li>
        );
      })}
    </ul>
  );
};

export default PrimaryMenu;
