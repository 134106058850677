import React, { useEffect } from 'react';
import Image, { ImageProps } from 'next/image';
import Link from 'next/link';
import throttle from 'lodash/throttle';
import clsx from 'clsx';
import { IImage } from 'src/services/api/types/IImage';
import { useAppContext } from 'src/context/app.context';
import ContainImage from '@components/views/ContainImage';

const BASE_LOGO_HEIGHT = 24;

interface NavLogoProps {
  productLogo?: IImage;
  secondaryLogo?: string | ImageProps['src'];
  setIsNavbarSticked?: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavLogo: React.FunctionComponent<NavLogoProps> = ({
  productLogo,
  setIsNavbarSticked,
}) => {
  const handleScroll = throttle(() => {
    if (window.scrollY > 10) {
      setIsNavbarSticked && setIsNavbarSticked(true);
    } else {
      setIsNavbarSticked && setIsNavbarSticked(false);
    }
  }, 100);

  const { conferenceLogo } = useAppContext();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <Link href="/">
      <a className="flex mr-32">
        <span className="flex-shrink-0" style={{ height: BASE_LOGO_HEIGHT }}>
          <Image
            src={'/images/Logo.svg'}
            alt="wsc"
            width={56}
            height={24}
            loading="eager"
          />
        </span>
        <span
          className={clsx('w-1 mx-12 flex-shrink-0 self-center', {
            'h-24 bg-neutral-9': !productLogo && !conferenceLogo,
            'h-16 bg-neutral-3': productLogo || conferenceLogo,
          })}
        />
        <div
          className={clsx('overflow-hidden relative flex-shrink-0', {
            '-mt-2': !productLogo && !conferenceLogo,
          })}
          style={{
            ...(productLogo && {
              marginBottom: BASE_LOGO_HEIGHT - productLogo.height,
              height: productLogo.height,
            }),
          }}
        >
          {productLogo && (
            <Image
              src={productLogo.url}
              width={productLogo.width}
              height={productLogo.height}
              alt={productLogo.title}
            />
          )}
          {conferenceLogo && (
            <div
              style={{
                width: conferenceLogo.width,
                height: conferenceLogo.height,
              }}
            >
              <ContainImage
                src={conferenceLogo.url}
                alt={conferenceLogo.title}
              />
            </div>
          )}
          {!conferenceLogo && !productLogo && (
            <div className={'flex items-center min-w-[12.3rem] min-h-[2.4rem]'}>
              <Image
                layout="fill"
                src={'/images/Logotype.svg'}
                alt="logo2 wsc"
                objectFit="contain"
                objectPosition="left"
                priority
              />
            </div>
          )}
        </div>
      </a>
    </Link>
  );
};
export default NavLogo;
