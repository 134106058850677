import React, { useContext } from 'react';
import MobileMenu from '@components/views/Menu/MobileMenu';
import { MenuContext } from '@components/views/Menu/context/Menu.context';
import CrossIcon from '../../../../assets/images/icons/cross-burger.svg';
import BurgerIcon from '../../../../assets/images/icons/burger.svg';

import { theme } from '@styles/theme';

const Burger = () => {
  const { isOpen, toggleOpenMenu } = useContext(MenuContext);

  return (
    <>
      <div onClick={toggleOpenMenu} className="cursor-pointer">
        {isOpen ? (
          <CrossIcon fill={theme.colors.neutral[9]} />
        ) : (
          <BurgerIcon fill={theme.colors.neutral[9]} />
        )}
      </div>
      <MobileMenu />
    </>
  );
};

export default Burger;
