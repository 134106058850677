import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/vendor/tailwind.css';
import GlobalStyle from 'src/styles/globalStyle';
import NProgress from 'nprogress';
import type { AppProps } from 'next/app';
import { FC, useEffect, useState } from 'react';
import { Hydrate } from 'react-query/hydration';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router } from 'next/router';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { theme } from 'src/styles/theme';
import EditorWrapper from '@components/views/EditorWrapper';
import { handleSpinnerPosition } from 'src/utils/spinnerPosition';
import ErrorBoundary from '@components/containers/ErrorBoundary';

NProgress.configure({ showSpinner: true });
Router.events.on('routeChangeStart', () => {
  NProgress.start();
  handleSpinnerPosition();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const Noop: FC = ({ children }) => <>{children}</>;

//TODO: check app props type
export default function MyApp({ Component, pageProps }: AppProps<any>) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: Infinity,
          },
        },
      }),
  );

  const Layout = (Component as any).Layout || Noop;

  useEffect(() => {
    import('smooth-scroll').then(({ default: SmoothScroll }) => {
      new SmoothScroll('a[href*="#"]', {
        speed: 300,
        topOnEmptyHash: true,
        offset: 80,
        updateURL: true,
      });
    });
  }, []);

  return (
    <>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <Hydrate state={pageProps.dehydratedState}>
            <Layout pageProps={pageProps}>
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <EditorWrapper isPage>
                  <Component {...pageProps} />
                </EditorWrapper>
                <ToastContainer />
              </ThemeProvider>
            </Layout>
          </Hydrate>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  );
}
