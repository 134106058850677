interface IOptions {
  textColor?: string;
  className?: string;
  backgroundColor?: string;
  fontSize?: string;
}

export const convertGutenbergClassToTailwind = (
  options: IOptions = {
    className: '',
  },
) => {
  let { className } = options;
  const { textColor, backgroundColor, fontSize } = options;

  if (textColor) className += ` text-${textColor}`;
  if (backgroundColor) className += ` bg-${backgroundColor}`;
  if (fontSize) className += ` text-${fontSize}`;

  return className;
};
