import { useEffect } from 'react';
import { useCookiesContext } from 'src/context/cookies.context';
import TagManager from 'react-gtm-module';

function hideCookiebotHandler() {
  if (typeof Cookiebot !== 'undefined') {
    if (Cookiebot.consented) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID || '',
      });
    }
    Cookiebot.hide();
  }
}

const useRegisterCookiebotEvents = () => {
  const { setConsent, hideCookiesBar, cookiesDialogLoading } =
    useCookiesContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setCookieHandler = () => {
    setConsent(Cookiebot.consent || {});
    if (Cookiebot.consented) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID || '',
      });
    }

    if (Cookiebot.changed) {
      hideCookiesBar();
    }
  };

  useEffect(() => {
    if (typeof Cookiebot === 'undefined' || !Cookiebot?.consent) return;

    const handleDialogIsNotLoading = () => cookiesDialogLoading(false);

    window.addEventListener('CookiebotOnLoad', hideCookiebotHandler);
    window.addEventListener('CookiebotOnAccept', setCookieHandler);
    window.addEventListener('CookiebotOnDecline', setCookieHandler);
    window.addEventListener(
      'CookiebotOnDialogDisplay',
      handleDialogIsNotLoading,
    );

    return () => {
      window.removeEventListener('CookiebotOnLoad', hideCookiebotHandler);
      window.removeEventListener('CookiebotOnAccept', setCookieHandler);
      window.removeEventListener('CookiebotOnDecline', setCookieHandler);
      window.removeEventListener(
        'CookiebotOnDialogDisplay',
        handleDialogIsNotLoading,
      );
    };
  }, [setCookieHandler, cookiesDialogLoading]);
};

export default useRegisterCookiebotEvents;
