import { mediaQueries } from '@styles/theme';
import omit from 'lodash/omit';
import React from 'react';
import styled, { css } from 'styled-components';

const handleMarginType = (value: OuterMarginValue) => {
  switch (value) {
    case 'RIGHT':
      return css`
        ${mediaQueries.lessThan('xxxl')`
          max-width: unset;
          padding-left: calc((50% - var(--container-width) / 2));
       `}
      `;
    case 'LEFT':
      return css`
        ${mediaQueries.lessThan('xxxl')`
          max-width: unset;
          padding-right: calc((50% - var(--container-width) / 2));
       `}
      `;
    case 'BOTH':
      return css`
        max-width: unset;
      `;
    default:
      return;
  }
};

const ContainerStyled = styled.div<
  {
    outerMargin?: OuterMarginValue;
  } & Partial<ContainerProps>
>`
  width: 100%;
  max-width: var(--container-width);
  margin-left: auto;
  margin-right: auto;

  &.container--narrow {
    max-width: 768px;

    ${mediaQueries.lessThan('lg')`
      max-width: var(--container-width);
    `}
  }

  &.container--wide {
    max-width: 1304px;

    ${mediaQueries.lessThan('lg')`
      max-width: var(--container-width);
    `}
  }

  ${({ isStatic }) =>
    !isStatic &&
    css`
      position: relative;
    `}

  ${({ hasImportantMaxWidth }) =>
    hasImportantMaxWidth &&
    css`
      max-width: var(--container-width) !important;
    `}

    ${mediaQueries.lessThan('xl')`
    .styled-container:not(.group-container) {
      max-width: none;
    }
    `}

  ${({ isWide, hasPaddingLeft }) =>
    isWide &&
    css`
      width: 100%;
      max-width: auto;

      ${mediaQueries.greaterThan('xl')`
      --margin: 13.2rem;
      `}

      ${mediaQueries.lessThan('xl')`
        .styled-container {
          padding-left: 1.6rem;
          padding-right: 1.6rem;
        }
      `}

      ${mediaQueries.greaterThan('xxl')`
        --margin: 6.4rem;
        max-width: calc(100% - var(--margin));

        ${
          hasPaddingLeft
            ? `padding-left: calc((50% - var(--container-width) / 2 - 2px) - var(--margin) / 2);`
            : ''
        }
      `}

      ${mediaQueries.greaterThan('xxxl')`
        --max-width: 160rem;
        max-width: var(--max-width);

        ${
          hasPaddingLeft
            ? `padding-left: calc((50vw - (100% - var(--max-width)) / 2) - var(--container-width) / 2);`
            : ''
        }
      `}
    `}

  ${({ outerMargin }) => outerMargin && handleMarginType(outerMargin)}
`;

type OuterMarginValue = 'RIGHT' | 'LEFT' | 'BOTH';
export interface ContainerProps {
  className?: string;
  isWide?: boolean;
  outerMargin?: OuterMarginValue;
  hasPaddingLeft?: boolean;
  isStatic?: boolean;
  hasImportantMaxWidth?: boolean;
}

const Container: React.FunctionComponent<ContainerProps> = (props) => {
  const { children } = props;

  return (
    <ContainerStyled
      {...omit(props, 'children')}
      className={`${props.className} styled-container`}
    >
      {children}
    </ContainerStyled>
  );
};

export default Container;
