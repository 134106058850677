import React from 'react';
import Head from 'next/head';
import parseStringToHTML from 'html-react-parser';

export interface PageHeadProps {
  yoast_head: string;
}

const PageHead: React.FunctionComponent<PageHeadProps> = ({
  yoast_head,
  children,
}) => {
  return (
    <Head>
      {parseStringToHTML(yoast_head)}
      {children}
    </Head>
  );
};

export default PageHead;
