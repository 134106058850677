import Footer from '@components/views/Footer';
import Navbar from '@components/views/Navbar';
import PageHead from '@components/containers/PageHead';
import React, { FC } from 'react';
import TopBar from '@components/views/TopBar';
import { useOptionsQuery } from 'src/services/api/queries/hooks/useOptionsQuery';
import NavbarMobile from '@components/views/NavbarMobile';
import { MenuContextProvider } from '@components/views/Menu/context/Menu.context';
import { AppProvider } from 'src/context/app.context';
import {
  ProductColors,
  ColorsProvider,
  ConferenceColors,
} from 'src/context/colors.context';
import { CookiesProvider } from 'src/context/cookies.context';
import useRegisterCookiebotEvents from 'src/hooks/useRegisterCookiebotEvents';
import useWatchForRemoveCookies from 'src/hooks/useWatchForRemoveCookies';
import ScrollToTopButton from '@components/containers/ScrollToTopButton/ScrollToTopButton';
import useCopyingElement from 'src/hooks/useCopyingElement';
import { usePreviewMode } from 'src/hooks/usePreviewMode';
import dynamic from 'next/dynamic';
import { IImageAcf } from 'src/types/IImageAcf';
const CookiesConsentBar = dynamic(
  () => import('../../CookiesConsentBar/CookiesConsentBar'),
  { ssr: false },
);
import dayjs from 'dayjs';
// TODO: import by dynamic based on current language
require('dayjs/locale/pl');
export interface MainTemplateProps {
  yoast_head?: string;
  customHead?: React.ReactNode;
  announcement?: string;
  productLogo?: IImageAcf;
  yoast_head_json?: any;
  productColors?: ProductColors;
  conferenceColors?: ConferenceColors;
  conferenceLogo?: IImageAcf;
  permalink?: string;
}

const MainTemplate: React.FunctionComponent<MainTemplateProps> = ({
  yoast_head,
  customHead,
  children,
  announcement,
  productLogo,
  yoast_head_json,
  productColors,
  conferenceColors,
  conferenceLogo,
  permalink,
}) => {
  const settingData = useOptionsQuery()?.data;
  usePreviewMode();

  dayjs.locale('pl');

  return (
    <AppProvider
      yoastHeadJson={yoast_head_json}
      conferenceLogo={conferenceLogo}
      permalink={permalink}
    >
      <CookiesProvider>
        <ColorsProvider
          productColors={{
            main: productColors?.main,
            washed: productColors?.washed,
          }}
          conferenceColors={conferenceColors}
        >
          <div className="min-h-screen flex flex-col justify-between">
            <MainTemplateInner>
              <TopBar
                announcement={announcement || settingData?.announcement}
              />
              <MenuContextProvider>
                <Navbar productLogo={productLogo} />
                <NavbarMobile productLogo={productLogo} />
              </MenuContextProvider>
              {(yoast_head || customHead) && (
                <PageHead yoast_head={yoast_head || ''}>{customHead}</PageHead>
              )}
              {children}
            </MainTemplateInner>
            <Footer />
          </div>
          <ScrollToTopButton />
          <CookiesConsentBar />
        </ColorsProvider>
      </CookiesProvider>
    </AppProvider>
  );
};

const MainTemplateInner: FC<Partial<MainTemplateProps>> = ({ children }) => {
  useRegisterCookiebotEvents();
  useWatchForRemoveCookies();

  const { ref } = useCopyingElement();

  return <div ref={ref}>{children}</div>;
};

export default MainTemplate;
