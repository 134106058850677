import React from 'react';
import styled from 'styled-components';
import { theme } from '@styles/theme';

export interface GalleryProps {
  attrs?: { columns: number };
  innerBlocks: any[];
}

const StyledWrapper = styled.div<{ columns: number }>`
  .wp-block-gallery {
    gap: 1.6rem;
    display: flex;
    flex-wrap: wrap;
  }

  .wp-block-gallery .wp-block-image {
    margin: 0 auto !important;
    flex-grow: 1;
    max-width: unset;

    @media screen and (min-width: ${theme.screens.sm}) {
      width: calc(50% - 1.6rem);
    }

    @media screen and (min-width: ${theme.screens.lg}) {
      width: ${({ columns }) => `calc(${100 / columns}% - 1.6rem)`};
    }
  }
`;

const Gallery: React.FunctionComponent<GalleryProps> = (props) => {
  const { attrs, children } = props;
  return (
    <StyledWrapper columns={attrs?.columns || 3}>{children}</StyledWrapper>
  );
};
export default Gallery;
