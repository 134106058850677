import { css } from 'styled-components';

const nprogress = css`
  #nprogress {
    .bar {
      background: ${({ theme }) => theme.colors.primary['DEFAULT']};
    }

    .peg {
      box-shadow: 0 0 10px ${({ theme }) => theme.colors.primary['DEFAULT']},
        0 0 5px ${({ theme }) => theme.colors.primary['DEFAULT']};
    }

    .spinner-icon {
      border-top-color: ${({ theme }) => theme.colors.primary['DEFAULT']};
      border-left-color: ${({ theme }) => theme.colors.primary['DEFAULT']};
    }
  }
`;

export default nprogress;
