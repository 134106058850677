import { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import displayToast from 'src/utils/displayToast';
import CopyIcon from '../assets/images/icons/so-copy.svg';

const useCopyingElement = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const copyingElems = ref.current.querySelectorAll('.copying');

      if (copyingElems && !!copyingElems.length) {
        copyingElems.forEach((copyingElem) => {
          const iconElem = copyingElem.querySelector('.copying__icon');

          if (!iconElem) {
            const className = 'cursor-copy';
            copyingElem.classList.add(className);

            copyingElem.addEventListener('click', () => {
              const copingAttribute = copyingElem.getAttribute('data-coping');

              navigator.clipboard.writeText(
                copingAttribute
                  ? copingAttribute
                  : copyingElem.textContent || '',
              );

              displayToast({ title: 'Skopiowano do schowka!' });
            });

            const el = document.createElement('span');
            el.classList.add(className, 'copying__icon');
            el.innerHTML = ReactDOMServer.renderToStaticMarkup(
              <span>
                {' '}
                <CopyIcon className="inline" />
              </span>,
            );
            copyingElem.appendChild(el);
          }
        });
      }
    }
  }, [ref]);

  return { ref };
};

export default useCopyingElement;
