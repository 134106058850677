import Image, { ImageProps } from 'next/image';
import styled, { css } from 'styled-components';
import clsx from 'clsx';

interface ContainImageProps extends ImageProps {
  height?: string;
  width?: string;
  fillHeight?: boolean;
}

const ContainImageStyled = styled.div<ContainImageProps>`
  span,
  img {
    position: static !important;
    ${({ fillHeight }) =>
      fillHeight &&
      css`
        height: 100% !important;
      `}
  }
  img {
    height: ${({ height }) => (height ? `${height}` : 'unset')} !important;
    width: ${({ width }) => (width ? `${width}` : 'unset')} !important;
  }
`;

const ContainImage: React.FunctionComponent<ContainImageProps> = (props) => {
  return (
    <ContainImageStyled
      className={clsx('w-full', { 'h-full': props.fillHeight })}
      {...props}
    >
      <Image layout="fill" alt="" {...props} objectFit="cover" />
    </ContainImageStyled>
  );
};

export default ContainImage;
