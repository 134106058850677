import { GET_OPTIONS } from './../../../CONSTANTS';
import { QueryTuple } from './../types';
import OptionsApi from '../../wp/OptionsApi';

const getOptionsData = async () => (await new OptionsApi().getAll()).data;

const optionsQueryGetterTuple: QueryTuple = [
  [GET_OPTIONS],
  async () => getOptionsData(),
];

export { optionsQueryGetterTuple, getOptionsData };
