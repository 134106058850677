import clsx from 'clsx';
import React, { useContext } from 'react';
import { useOptionsQuery } from 'src/services/api/queries/hooks/useOptionsQuery';
import styled from 'styled-components';
import { MenuContext } from '@components/views/Menu/context/Menu.context';
import PrimaryMenu from '@components/views/Menu/PrimaryMenuMobile';

const MobileMenuStyled = styled.div`
  max-height: calc(100vh - 7.2rem);
`;

const MobileMenu = () => {
  const menus = useOptionsQuery().data?.menus;
  const { isOpen, openedMenu } = useContext(MenuContext);

  if (!isOpen) return null;

  return (
    <MobileMenuStyled
      className={clsx(
        'py-24 shadow-xl transition-all absolute bottom-0 left-0 border-top translate-y-full bg-neutral-0 w-full z-10 overflow-auto max-h-screen',
      )}
    >
      {menus.primary && <PrimaryMenu items={menus.primary} />}
      {!openedMenu && (
        <div className="h-1 bg-neutral-1 !max-w-[var(--container-width)] mx-auto my-32" />
      )}
      {menus.primary_side && <PrimaryMenu items={menus.primary_side} />}
      {/* //TODO: here should be secondary menu. Archiclub button shouldn't be part of secondary menu. */}
    </MobileMenuStyled>
  );
};

export default MobileMenu;
