import { MenuContext } from '@components/views/Menu/context/Menu.context';
import clsx from 'clsx';
import { useContext } from 'react';

const Backdrop = () => {
  const { openedMenu, closeMegaMenuHandler } = useContext(MenuContext);

  return (
    <div
      onMouseEnter={() => closeMegaMenuHandler(undefined)}
      className={clsx(
        'invisible xl:visible fixed bottom-0 top-0 left-0 right-0 bg-neutral-9 opacity-20 h-full z-30 cursor-pointer',
        {
          'pointer-events-none xl:invisible': !openedMenu,
        },
      )}
    />
  );
};

export default Backdrop;
