import React, { useCallback } from 'react';
import { ISearchResponse } from 'src/services/api/types/ISearch';
import SearchLink from '@components/views/SearchLink';

export interface SearchItemProps {
  result: ISearchResponse;
  search: string;
}

const SearchItem: React.FunctionComponent<SearchItemProps> = ({
  result,
  search,
}) => {
  const highlightText = useCallback(
    (text: string) => {
      return search
        ? text.replace(
            new RegExp(search, 'gi'),
            `<span class='font-bold'>${search}</span>`,
          )
        : text;
    },
    [search],
  );

  return (
    <li key={`${result.title}-${result.id}`} className="mb-2">
      <SearchLink href={result.url}>
        <div
          dangerouslySetInnerHTML={{
            __html: highlightText(result.title),
          }}
        />
      </SearchLink>
    </li>
  );
};
export default SearchItem;
