import Container from '@components/containers/Container';
import React, { useState } from 'react';
import PrimaryMenu from '../Menu/PrimaryMenu';
import NavLogo from '@components/containers/NavLogo';
import Backdrop from '../Menu/MegaMenu/organisms/Backdrop';
import clsx from 'clsx';
import { IImage } from 'src/services/api/types/IImage';
import { useOptionsQuery } from 'src/services/api/queries/hooks/useOptionsQuery';

const Navbar = ({ productLogo }: { productLogo?: IImage }) => {
  const [isSticked, setIsSticked] = useState(false);
  const menu = useOptionsQuery().data?.menus;

  return (
    <>
      <Backdrop />
      <header
        id="site-navbar"
        className={clsx(
          'invisible xl:visible hidden xl:block sticky top-0 w-full z-30 transition-colors',
          {
            'bg-neutral-1': isSticked,
            'bg-neutral-0': !isSticked,
          },
        )}
      >
        <div className="relative flex items-center justify-between w-full py-24 border-b-1 border-b-neutral-1 h-[7.3rem]">
          <Container isStatic>
            <div className="flex items-center gap-32 justify-between">
              <div className="flex items-center">
                <NavLogo
                  productLogo={productLogo}
                  setIsNavbarSticked={setIsSticked}
                />
                {menu?.primary && <PrimaryMenu items={menu.primary} />}
              </div>

              <div>
                {menu?.primary_side && (
                  <PrimaryMenu items={menu.primary_side} />
                )}
                {/* //TODO: here should be secondary menu. Archiclub button shouldn't be part of secondary menu. */}
              </div>
            </div>
          </Container>
        </div>
      </header>
    </>
  );
};

export default Navbar;
