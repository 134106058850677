import Link from 'next/link';
import React, { useContext } from 'react';
import { IMenu } from 'src/services/api/types/IMenu';
import ChevronIcon from '../../../../../assets/images/icons/chevron-down.svg';
import { theme } from '@styles/theme';
import { MenuContext } from '../../context/Menu.context';
import clsx from 'clsx';
import Image from 'next/image';
import HighlightedMenuItemLink from '@components/views/Menu/PrimaryMenuShared/HighlightedMenuItemLink/HighlightedMenuItemLink';
export interface PrimaryMenuItemLinkProps {
  menuItem: IMenu;
  hasChildren?: boolean;
}

const PrimaryMenuItemLink: React.FunctionComponent<
  PrimaryMenuItemLinkProps
> = ({ menuItem, hasChildren }) => {
  const { openedMenu } = useContext(MenuContext);
  const isCurrentElementActive = menuItem.mega_menu_id === openedMenu;

  return (
    <div
      className={clsx('px-16 lg:px-0 flex items-center justify-between', {
        'text-neutral-9': isCurrentElementActive,
        'text-neutral-4': openedMenu && !isCurrentElementActive,
      })}
    >
      {menuItem.icon && !hasChildren ? (
        <Link href={menuItem.url}>
          <a>
            <HighlightedMenuItemLink
              icon={menuItem.icon}
              title={menuItem.title}
            />
          </a>
        </Link>
      ) : (
        <Link href={menuItem.url}>
          <a
            className={clsx(
              'transition-colors hover:text-primary flex items-center',
            )}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: menuItem.title,
              }}
            />
            {menuItem.icon && (
              <div className="relative w-14 h-14 ml-10">
                <Image
                  src={menuItem.icon}
                  layout="fill"
                  objectFit="contain"
                  alt=""
                />
              </div>
            )}
          </a>
        </Link>
      )}
      {hasChildren && (
        <div
          className={clsx('cursor-pointer transition duration-150', {
            'rotate-180 ': isCurrentElementActive,
          })}
        >
          <ChevronIcon
            fill={
              openedMenu && !isCurrentElementActive
                ? theme.colors.neutral[4]
                : theme.colors.neutral[9]
            }
          />
        </div>
      )}
    </div>
  );
};

export default PrimaryMenuItemLink;
