import { useEffect, useState } from 'react';
import { RecentSearchCookieName as CookieName } from 'src/constants/cookiesConstants';
import { useCookiesContext } from 'src/context/cookies.context';
import { getCookie } from 'src/utils/cookies';

const useGetRecentSearchParams = () => {
  const [params, setParams] = useState<unknown>(null);
  const {
    cookies: { consent },
  } = useCookiesContext();

  useEffect(() => {
    const recentQueriesCollection: unknown = getCookie(CookieName);

    if (!recentQueriesCollection || !consent.necessary) return;

    setParams(recentQueriesCollection);
  }, []);

  return params;
};

export default useGetRecentSearchParams;
