import camelCase from 'lodash/camelCase';
import { extractInlineStylesFromHtmlString } from './extractInlineStylesFromHtmlString';

export const convertInlineStylesToObject = (styleString: string) => {
  const result: { [key: string]: string } = {};
  const inlineStyles = extractInlineStylesFromHtmlString(styleString);
  const arrayOfStyles = inlineStyles.split(';');

  arrayOfStyles.forEach((style) => {
    const [name, value] = style.split(':');
    if (name && value) {
      result[camelCase(name)] = value;
    }
  });

  return result;
};
