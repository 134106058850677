import { IApi } from '../types/IApi';
import { IOptionsAPiResponse } from '../types/IOptions';
import axiosInstance from './axiosInstance';

// Get all ACF settings theme options
class OptionsApi implements IApi {
  basePath = 'wp/v2/options';

  async getAll() {
    return axiosInstance.get<IOptionsAPiResponse>(this.basePath);
  }
}

export default OptionsApi;
