import ClipLoader from 'react-spinners/ClipLoader';
import React from 'react';
import { LoaderSizeProps } from '../../../../node_modules/react-spinners/interfaces';
import { theme } from '@styles/theme';

const Spinner: React.FunctionComponent<LoaderSizeProps> = (props) => {
  return <ClipLoader color={theme.colors.primary['DEFAULT']} {...props} />;
};

export default Spinner;
