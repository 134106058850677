import React from 'react';
import Container from '@components/containers/Container';
import Heading from '@components/views/Heading';
import Button from '@components/views/Button';
import MainTemplate from '@components/containers/templates/MainTemplate';
import styled from 'styled-components';
import { mediaQueries } from '@styles/theme';
import routes from 'src/routes/routes';

const Styled500 = styled.div`
  height: 70vh;

  h1 {
    font-size: 3.6rem;

    ${mediaQueries.greaterThan('lg')`
      font-size: 10vw;
    `};
  }

  h2 {
    font-size: 2.4rem;

    ${mediaQueries.greaterThan('lg')`
      font-size: 2vw;
    `};
  }
`;

const content = {
  title: '500',
  description: 'Przepraszamy. Coś poszło nie tak.',
  button: {
    href: routes.homepage.url,
    label: 'Wróć na stronę główną',
  },
};

const Custom500 = () => {
  const { title, description, button } = content;

  return (
    <MainTemplate
      customHead={
        <>
          <title>
            {title} - {description}
          </title>
          <meta name="robots" content="noindex" />
        </>
      }
    >
      <Container>
        <Styled500 className="center flex justify-center flex-col items-center w-full text-center">
          <Heading level="h1" title={title} />
          <Heading level="h2" title={description} />
          <div className="mt-32">
            <Button href={button.href}>{button.label}</Button>
          </div>
        </Styled500>
      </Container>
    </MainTemplate>
  );
};

export default Custom500;
