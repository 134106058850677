import { ParsedUrlQueryInput } from 'querystring';
import routes from 'src/routes/routes';

interface SearchParams {
  search: string;
  subtypes?: string[];
}

interface SearchRouterParams {
  pathname: string;
  query: ParsedUrlQueryInput;
}

export const getSearchRouterParams = ({ search, subtypes }: SearchParams) => {
  const params: SearchRouterParams = {
    pathname: routes.search.url,
    query: {
      search,
    },
  };

  if (subtypes && subtypes.length) {
    params.query.subtypes = subtypes;
  }

  return params;
};
