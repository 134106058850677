import React from 'react';

const SmallHeading = ({ label }: { label: string }) => {
  return (
    <p
      className="text-neutral-4 uppercase tracking-widest text-xsExtra font-extrabold block"
      dangerouslySetInnerHTML={{
        __html: label,
      }}
    />
  );
};

export default SmallHeading;
