import { useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const usePreviewMode = () => {
  const queryClient = useQueryClient();
  const router = useRouter();

  useEffect(() => {
    const { wp_secret_api_key } = router.query;

    if (
      wp_secret_api_key &&
      wp_secret_api_key === process.env.NEXT_PUBLIC_WP_SECRET_API_KEY
    ) {
      queryClient.invalidateQueries();
      queryClient.setDefaultOptions({
        queries: {
          staleTime: 1,
        },
      });
    }
  }, [router, queryClient]);
};
