import ArrowIcon from '../../../assets/images/icons/chevron-down.svg';
import classNames from 'clsx';
import { theme } from '@styles/theme';
import useObserveScrollPosition from 'src/hooks/useObserveScrollPosition';

const ScrollToTopButton = () => {
  const { isBelowRoot: isVisible } = useObserveScrollPosition(100);

  function scrollToTopHandler() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div
      className={classNames(
        'cursor-pointer w-56 h-56 bg-neutral-0 transform hover:border-neutral-3 duration-150 fixed bottom-24 right-24 rounded-56 flex justify-center items-center border transition-all z-50 group',
        { 'invisible opacity-0 translate-y-20': !isVisible },
        { 'visible opacity-100 ': isVisible },
      )}
      onClick={scrollToTopHandler}
    >
      <ArrowIcon
        className="transition-transform transform rotate-180 group-hover:-translate-y-2"
        fill={theme.colors.neutral[9]}
      />
    </div>
  );
};

export default ScrollToTopButton;
