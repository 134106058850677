export const extractInlineStylesFromHtmlString = (htmlString: string) => {
  const reg = /style="(.+?)"/g;
  const res = reg.exec(htmlString);

  if (res && res.length >= 2) {
    const [, style] = res;
    return style;
  }

  return '';
};
