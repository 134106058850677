import PrimaryMenuItemLink from './PrimaryMenuItemLink';
import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { IMenu } from 'src/services/api/types/IMenu';
import { listToTree } from 'src/utils/listToTree';
import Container from '@components/containers/Container';
import { MenuContext } from '../context/Menu.context';
import MegaMenuMobile from '@components/views/Menu/MegaMenu/MegaMenuMobile';
import { handleOpenMenu } from '@components/views/Menu/utils/utils';

export interface PrimaryMenuProps {
  items: IMenu[];
}

const PrimaryMenu: React.FunctionComponent<PrimaryMenuProps> = ({ items }) => {
  const itemsTree = useMemo(() => listToTree(items), [items]);
  const { openedMenu, openMegaMenuHandler, closeMegaMenuHandler, closeMenu } =
    useContext(MenuContext);

  return (
    <div>
      <ul className={`flex flex-col`}>
        {itemsTree.map((menuItem) => {
          const hasChildren = menuItem.children && menuItem.children.length > 0;

          const hasBlocks =
            Array.isArray(menuItem.mega_menu_blocks) &&
            menuItem.mega_menu_blocks.length > 0;

          return (
            <li
              key={menuItem.ID}
              className={clsx(
                'text-lg md:text-base mb-24 last:mb-0 lg:mx-32 empty:mb-0 py-8 cursor-pointer',
                {
                  [menuItem.classes.join(' ')]: menuItem.children,
                  hidden: !!openedMenu,
                },
              )}
              onClick={closeMenu}
              {...((hasChildren || hasBlocks) && {
                onClick: () => {
                  handleOpenMenu(
                    menuItem.mega_menu_id,
                    openedMenu,
                    openMegaMenuHandler,
                    closeMegaMenuHandler,
                  );
                },
              })}
            >
              {!openedMenu && (
                <PrimaryMenuItemLink
                  menuItem={menuItem}
                  hasChildren={hasChildren || hasBlocks}
                />
              )}

              {hasChildren && renderGeneralSubMenu(menuItem)}
            </li>
          );
        })}
      </ul>
      <MegaMenuMobile menuItems={itemsTree} />
    </div>
  );
};

// LEVEL 1
const renderGeneralSubMenu = (items: IMenu) => {
  const { children } = items;
  const xlClasses = `xl:shadow-xl xl:absolute bottom-0 left-0 xl:translate-y-full xl:max-h-screen xl:overflow-auto`;

  return (
    <ul
      className={`hidden invisible transition-all border-t bg-neutral-0 lg:p-32 w-full z-10 pb-80 ${xlClasses}`}
    >
      <Container>
        {children.map((menuItem) => {
          const hasChildren = menuItem.children && menuItem.children.length > 0;
          return (
            <li
              key={menuItem.ID}
              className={clsx('relative', {
                [menuItem.classes.join(' ')]: menuItem.children,
              })}
            >
              <PrimaryMenuItemLink
                menuItem={menuItem}
                hasChildren={hasChildren}
              />
              {hasChildren && renderSubMenu(menuItem.children)}
            </li>
          );
        })}
      </Container>
    </ul>
  );
};

//LEVEL 2
const renderSubMenu = (items: IMenu['children']) => {
  return (
    <ul className="transition-all border  absolute top-full bg-neutral-0 py-8 px-12 w-168 z-10">
      {items.map((menuItem) => {
        const hasChildren = menuItem.children && menuItem.children.length > 0;
        return (
          <li
            key={menuItem.ID}
            className={clsx({
              [menuItem.classes.join(' ')]: menuItem.children,
            })}
          >
            <PrimaryMenuItemLink
              menuItem={menuItem}
              hasChildren={hasChildren}
            />
            {hasChildren && renderSubMenu(menuItem.children)}
          </li>
        );
      })}
    </ul>
  );
};

export default PrimaryMenu;
