export const getColorClassFromGutenberg = (innerHTML: string) => {
  const matches = innerHTML.match(/has-(.*?)-color/g);

  if (matches) {
    matches.forEach((match) => {
      match.split(' ').forEach((str) => {
        const _match = str.match(/(has-)(.*?)(-color)/);

        if (_match) {
          innerHTML = innerHTML.replace(_match[0], `text-${_match[2]}`);
        }
      });
    });
  }

  return innerHTML;
};
