import Image from 'next/image';
import clsx from 'clsx';
import ChevronIcon from '../../../../../assets/images/icons/chevron-right.svg';

const HighlightedMenuItemLink = (props: any) => {
  const { title, icon } = props;

  if (!title) return null;

  return (
    <div className={clsx('flex items-center group cursor-pointer')}>
      <div
        className={clsx(
          'flex items-start justify-start flex-shrink-0 flex-col h-24 overflow-hidden ',
        )}
      >
        <div
          className={clsx(
            'transition-all duration-150 ease-linear group-hover:-translate-y-1/2',
          )}
        >
          <div>{title}</div>
          <div>{title}</div>
        </div>
      </div>
      {icon && (
        <div className="relative h-24">
          <div
            className={clsx(
              'flex items-start justify-start flex-shrink-0 flex-col h-24 overflow-hidden ml-8',
            )}
          >
            <div
              className={clsx(
                'transition-all duration-150 ease-linear group-hover:-translate-y-1/2',
              )}
            >
              <div className="w-24 h-24 flex items-center flex-shrink-0 justify-center">
                <div className="relative w-18 h-18">
                  <Image
                    src={icon}
                    layout="fill"
                    objectFit="contain"
                    alt="ikona"
                    priority
                  />
                </div>
              </div>
              <div className="w-24 h-24 opacity-0" />
            </div>
          </div>
          <div className="absolute right-0 top-0 h-24 w-24 flex items-center justify-center opacity-0 group-hover:opacity-100">
            <div className="bg-neutral-1 h-40 w-40 scale-0 rounded-full group-hover:scale-100 duration-200 absolute delay-75" />

            <div className="relative z-10">
              <ChevronIcon fill="currentColor" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HighlightedMenuItemLink;
