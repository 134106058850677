import React, { ErrorInfo } from 'react';
import Custom500 from '@pages/500';
import { captureException } from '@sentry/core';

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(_: Error, errorInfo: ErrorInfo) {
    if (process.env.NODE_ENV === 'production') {
      captureException(errorInfo);
    }
  }
  render() {
    if (this.state.hasError) {
      return <Custom500 />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
