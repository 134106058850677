import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ReactNode,
} from 'react';
import Link from 'next/link';
import classNames from 'clsx';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import Spinner from '../Spinner';
import { theme } from '@styles/theme';
import ChevronIcon from '../../../assets/images/icons/chevron-right.svg';
import clsx from 'clsx';
import {
  ConferenceColors,
  ProductColors,
  useColors,
} from 'src/context/colors.context';
import styled, { css } from 'styled-components';
import { IButtonACF } from 'src/types/IButtonACF';
import useIsExternalUrl from 'src/utils/useIsExternalUrl';

const variantsToNotHaveChevron: Array<ButtonProps['variant']> = [
  'PRIMARY',
  'LINK',
  'SECONDARY',
  'BASE-LINK',
];

export interface ButtonProps {
  variant?:
    | 'PRIMARY'
    | 'PRIMARY-INVERT'
    | 'SECONDARY'
    | 'SECONDARY-LIGHT'
    | 'SECONDARY-INVERT'
    | 'GHOST'
    | 'PLAIN'
    | 'LINK'
    | 'TRANSPARENT-GRAY'
    | 'TRANSPARENT-LIGHT'
    | 'PLAIN-BASE'
    | 'DROPDOWN'
    | 'DROPDOWN-BOLD'
    | 'GHOST-LIGHT'
    | 'SIMPLE'
    | 'BASE-LINK';
  // TODO: Change href with url to adjust for acf link field
  href?: string;
  loading?: boolean;
  isDisabled?: boolean;
  noPadding?: boolean;
  icon?: {
    iconComponent: ReactNode;
    isReverse?: boolean;
  };
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  hasBiggerFont?: boolean;
  fullHeight?: boolean;
  useAccentColor?: boolean;
  showChevron?: boolean;
  customFontSize?: number;
  customPaddingY?: number;
  customPaddingX?: number;
  showOnDevice?: 'desktop' | 'mobile';
  chevronDown?: boolean;
}

const { colors } = theme;

const ButtonInnerStyled = styled.button<{
  hoverStateColors?: ProductColors;
  conferenceColors?: ConferenceColors;
  variant?: ButtonProps['variant'];
  hasBiggerFont?: ButtonProps['hasBiggerFont'];
  customFontSize?: ButtonProps['customFontSize'];
  customPaddingY?: ButtonProps['customPaddingY'];
  customPaddingX?: ButtonProps['customPaddingX'];
}>`
  ${({ hasBiggerFont }) =>
    hasBiggerFont &&
    css`
      font-size: 1.6rem;
    `}

  ${({ customFontSize }) =>
    customFontSize &&
    css`
      font-size: ${customFontSize}px;
    `}

  ${({ customPaddingY }) =>
    customPaddingY &&
    css`
      padding-top: ${customPaddingY}px;
      padding-bottom: ${customPaddingY}px;
    `}

  ${({ customPaddingX }) =>
    customPaddingX &&
    css`
      padding-right: ${customPaddingX}px;
      padding-left: ${customPaddingX}px;
    `}

  ${({ hoverStateColors, variant }) =>
    hoverStateColors &&
    hoverStateColors.main &&
    hoverStateColors.washed &&
    css`
      transition: 0.2s;

      ${variant === 'PRIMARY' &&
      css`
        &:hover {
          background-color: ${hoverStateColors.washed};
          color: ${hoverStateColors.main};
          border: 1px solid ${hoverStateColors.main};
        }
      `}

      ${variant === 'GHOST' &&
      css`
        &:hover {
          color: ${hoverStateColors.main};

          .inner-arrow {
            transition: 0.2s;

            border-color: ${hoverStateColors.main};
            background-color: ${hoverStateColors.washed};
            color: ${hoverStateColors.main};
          }
        }
      `}
    `}

  ${({ conferenceColors, variant }) =>
    conferenceColors &&
    conferenceColors.primary &&
    css`
      transition: 0.2s;

      ${variant === 'PRIMARY' &&
      css`
        background-color: ${conferenceColors.primary};
        color: ${theme.colors.neutral[0]};
        border: 1px solid ${conferenceColors.primary};

        &:hover {
          background-color: ${theme.colors.neutral[0]};
          color: ${conferenceColors.primary};
        }
      `}
    `}
`;

/**
 *
 * @link https://www.figma.com/file/bEoq4a8N0Yy6hvVvwAQxiS/WSC-%3A%3A-UI-Design?node-id=7%3A77
 */
const Button: React.FunctionComponent<
  ButtonProps &
    ButtonHTMLAttributes<HTMLButtonElement & HTMLAnchorElement> &
    AnchorHTMLAttributes<HTMLAnchorElement>
> = (props) => {
  const { productColors, conferenceColors } = useColors();

  const {
    variant = 'PRIMARY',
    href,
    loading,
    children,
    isDisabled,
    icon,
    size,
    noPadding,
    fullWidth,
    id,
    hasBiggerFont,
    fullHeight,
    useAccentColor,
    showChevron,
    showOnDevice,
    chevronDown,
  } = props;

  const shouldShowChevron =
    (href &&
      children &&
      !variantsToNotHaveChevron.includes(variant) &&
      !href.includes('#')) ||
    showChevron;

  const ButtonChildren = () => {
    return (
      <div className={'flex items-center'}>
        <div
          className={clsx('flex items-center', {
            'flex-row-reverse': icon?.isReverse,
          })}
        >
          {isString(children) ? (
            <span
              dangerouslySetInnerHTML={{
                __html: children,
              }}
            />
          ) : (
            <span>{children}</span>
          )}
          {icon?.iconComponent && (
            <span
              className={clsx({
                'ml-4':
                  (size === 'medium' || size === 'small') && !icon?.isReverse,
                'mr-4':
                  (size === 'medium' || size === 'small') && icon?.isReverse,
                'ml-12': !size && !icon?.isReverse,
                'mr-12': !size && icon?.isReverse,
              })}
            >
              {icon?.iconComponent}
            </span>
          )}
        </div>

        {loading && (
          <div className="ml-4 flex items-center">
            <Spinner
              size={20}
              color={
                variant === 'PRIMARY'
                  ? colors.neutral[9]
                  : colors.primary['DEFAULT']
              }
            />
          </div>
        )}
      </div>
    );
  };

  const ButtonInner = ({ id }: { id?: IButtonACF['id'] }) => (
    <ButtonInnerStyled
      {...omit(props, [
        'variant',
        'loading',
        'icon',
        'size',
        'fullWidth',
        'noPadding',
        'id',
      ])}
      hoverStateColors={productColors}
      conferenceColors={useAccentColor ? conferenceColors : undefined}
      variant={variant}
      hasBiggerFont={hasBiggerFont}
      id={id}
      className={classNames(
        `border border-transparent inline-flex justify-center items-center transition-all group styled-button`,
        {
          'text-neutral-0 bg-primary hover:bg-neutral-9 focus:shadow-focus fill-neutral-0':
            variant === 'PRIMARY',
          'text-neutral-9 bg-neutral-0 hover:bg-transparent hover:text-neutral-0 focus:shadow-focus fill-neutral-9':
            variant === 'PRIMARY-INVERT',
          'bg-transparent fill-neutral-9 border border-neutral-9 hover:bg-neutral-9 hover:text-neutral-0 hover:fill-neutral- focus:shadow-focus':
            variant === 'SECONDARY',
          'text-neutral-0 border border-neutral-0 fill-neutral-0 hover:fill-neutral-9 hover:text-neutral-9 hover:bg-neutral-0 focus:bg-transparent focus:text-neutral-0':
            variant === 'SECONDARY-INVERT',
          'bg-transparent border border-neutral-2 hover:border-neutral-9 focus:shadow-focus':
            variant === 'SECONDARY-LIGHT',
          'text-neutral-9 hover:text-primary hover:fill-neutral-0 p-0':
            variant === 'GHOST',
          'text-neutral-0 hover:text-neutral-3 fill-neutral-0 hover:fill-neutral-9 p-0':
            variant === 'GHOST-LIGHT',
          'text-neutral-4 bg-transparent hover:text-primary':
            variant === 'TRANSPARENT-GRAY',
          'pointer-events-none': loading || isDisabled,
          'rounded-full w-56 h-56 p-0': !children,
          'py-16 px-32':
            children &&
            variant !== 'GHOST' &&
            variant !== 'GHOST-LIGHT' &&
            !size &&
            !noPadding &&
            variant !== 'SIMPLE',
          'py-8 px-16': size === 'small' && !noPadding && variant !== 'SIMPLE',
          'py-12 px-16':
            size === 'medium' && !noPadding && variant !== 'SIMPLE',
          'opacity-16': isDisabled,
          'text-sm': variant !== 'PLAIN',
          'text-sm text-neutral-4 hover:underline hover:text-neutral-9 underline-offset-8':
            variant === 'LINK',
          'text-base text-neutral-4 hover:underline hover:text-neutral-9 underline-offset-8':
            variant === 'BASE-LINK',
          'text-xs fill-neutral-9 py-0 px-0': variant === 'PLAIN',
          'w-full': fullWidth,
          'fill-neutral-9 py-0 px-0': variant === 'DROPDOWN',
          'fill-neutral-9 py-0 px-0 font-extrabold':
            variant === 'DROPDOWN-BOLD',
          'text-lg fill-neutral-9 py-0 px-0': variant === 'PLAIN-BASE',
          'hover:text-neutral-2': variant === 'TRANSPARENT-LIGHT',
          'h-full': fullHeight,
          'hidden xl:inline-flex': showOnDevice === 'desktop',
          'xl:hidden': showOnDevice === 'mobile',
        },
      )}
    >
      <ButtonChildren />
      {shouldShowChevron && (
        <span
          className={clsx({
            'inner-arrow ml-16 w-56 h-56 flex items-center justify-center rounded-full border transition-transform duration-150 group-hover:translate-x-2':
              variant === 'GHOST' || variant === 'GHOST-LIGHT',
            'ml-12 transition duration-75 group-hover:translate-x-2':
              variant !== 'GHOST',
            'border-neutral-9 group-hover:border-primary transition-colors':
              variant === 'GHOST',
            'border-neutral-0 group-hover:bg-neutral-0':
              variant === 'GHOST-LIGHT',
            'transform rotate-90': chevronDown,
          })}
          style={{
            ...(conferenceColors &&
              useAccentColor && {
                borderColor: conferenceColors.primary || '',
              }),
            ...(conferenceColors &&
              useAccentColor &&
              variant === 'SIMPLE' && {
                color: conferenceColors.primary || '',
              }),
          }}
        >
          <ChevronIcon
            fill="currentColor"
            className={clsx({
              'transition-transform transform group-hover:translate-x-2':
                variant === 'TRANSPARENT-LIGHT',
            })}
          />
        </span>
      )}
      {!children && <ChevronIcon fill="currentColor" />}
    </ButtonInnerStyled>
  );

  const isExternal = useIsExternalUrl(href || '');

  if (href) {
    if (href.includes('#') || isExternal) {
      return (
        <a
          href={href}
          rel="noreferrer"
          target={isExternal ? '_blank' : '_self'}
          id={id}
        >
          <ButtonInner />
        </a>
      );
    }

    return (
      <Link href={href} passHref>
        <a id={id}>
          <ButtonInner />
        </a>
      </Link>
    );
  }

  return <ButtonInner id={id} />;
};

Button.defaultProps = {
  variant: 'PRIMARY',
  loading: false,
  fullWidth: false,
};

export default Button;
