import { toast, ToastOptions } from 'react-toastify';

interface useToastProps {
  title: string;
  description?: string;
  options?: ToastOptions;
}

const displayToast = ({
  title,
  description,
  options = {
    type: 'success',
  },
}: useToastProps) => {
  return toast(
    <div>
      <h3 className="font-medium text-lg">{title}</h3>
      {description && <p className="text-neutral-4">{description}</p>}
    </div>,
    {
      theme: 'dark',
      position: 'bottom-right',
      ...options,
    },
  );
};

export default displayToast;
