import Blocks from '@components/containers/Blocks';
import Container from '@components/containers/Container';
import clsx from 'clsx';
import styled from 'styled-components';
import { IMenu } from 'src/services/api/types/IMenu';
import { MenuContext } from '../context/Menu.context';
import { mediaQueries } from '@styles/theme';
import { useContext } from 'react';

const MegaMenuStyled = styled.ul`
  ${mediaQueries.greaterThan('xl')`
  max-height: calc(100vh - 7.2rem);
 `}
`;

const MegaMenu = ({ menuItem }: { menuItem: IMenu }) => {
  const { mega_menu_blocks: gutenberg_blocks } = menuItem;
  const { isOpen, openedMenu } = useContext(MenuContext);

  const isVisible = openedMenu === menuItem.mega_menu_id;

  const xlClasses = `xl:my-0 xl:shadow-xl xl:absolute bottom-0 left-0 xl:translate-y-full`;

  return (
    <MegaMenuStyled
      className={clsx(
        `border-t border-neutral-1 bg-neutral-0 py-16 my-8 lg:my-24 xl:p-48 w-full z-10 lg:pb-80 xl:overflow-auto ${xlClasses}`,
        {
          'hidden invisible xl:block': !isVisible,
          'block visible': isVisible,
          'invisible xl:visible': !isOpen && isVisible,
        },
      )}
    >
      <Container
        hasImportantMaxWidth
        className={clsx({
          'opacity-0 hidden invisible xl:block': !isVisible,
          'opacity-100 block visible transition-all duration-[1000ms]':
            isVisible,
          'opacity-0 invisible xl:opacity-100 xl:visible ':
            !isOpen && isVisible,
        })}
      >
        {gutenberg_blocks && <Blocks blocks={gutenberg_blocks} />}
      </Container>
    </MegaMenuStyled>
  );
};

export default MegaMenu;
