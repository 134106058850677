import Cookies, { CookieAttributes } from 'js-cookie';

export const DEFAULT_COOKIE_OPTIONS: CookieAttributes = {
  expires: 14,
};

export const setCookie = (
  name: string,
  content: unknown,
  isCookieConsentAccepted: boolean,
  options: CookieAttributes = {
    ...DEFAULT_COOKIE_OPTIONS,
  },
) => {
  if (!isCookieConsentAccepted) {
    return false;
  }

  const cookieValue = JSON.stringify(content);
  return Cookies.set(name, cookieValue, options);
};

export const getCookie = (name: string): unknown => {
  const cookie = Cookies.get(name);

  return cookie ? JSON.parse(cookie) : null;
};

export const removeCookie = (name: string): void => Cookies.remove(name);

export const isCookieConsented =
  typeof Cookiebot !== 'undefined' && Cookiebot.consented;
