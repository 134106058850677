import { useOptionsQuery } from 'src/services/api/queries/hooks/useOptionsQuery';
import Column from '@components/views/Footer/Column';
import Container from '@components/containers/Container';
import dayjs from 'dayjs';
import FacebookIcon from '../../../assets/images/icons/Facebook.svg';
import InstagramIcon from '../../../assets/images/icons/Instagram.svg';
import Image from 'next/image';
import Link from 'next/link';
import LinkedinIcon from '../../../assets/images/icons/Linkedin.svg';
import React from 'react';
import YoutubeIcon from '../../../assets/images/icons/Youtube.svg';
import ContactItem from '@components/views/Footer/ContactItem';

const contactItems = [
  { text: 'WSC Witold Szymanik i S-ka Sp.z o.o.' },
  { text: 'Dane Rejestrowe', link: '/kontakt#dane-rejestrowe' },
  { text: 'Biuro handlowe' },
  { text: 'ul. Brukselska 44 lok. 2' },
  { text: '03-973 Warszawa' },
  { text: 'tel. +48 22 517 00 00', link: 'tel:+48225170000' },
  { text: '+48 22 517 00 50 (wsp. tech.)', link: 'tel:+48225170050' },
  { text: 'wsc@wsc.pl', link: 'mailto:wsc@wsc.pl' },
];

const Footer = () => {
  const themeOptionsData = useOptionsQuery().data;
  const footerData = themeOptionsData?.footer;
  const menu = footerData?.meta_data.menu.map((menu) => {
    return {
      name: menu.title,
      items: menu.column.map((column) => {
        const { menu_item } = column;

        return {
          name: menu_item.title,
          url: menu_item.url,
          target: menu_item.target,
        };
      }),
    };
  });
  const additional_links = footerData?.meta_data.additional_links;
  const socials = themeOptionsData?.socials.map(({ link }) => link);
  const year = dayjs().format('YYYY');

  return (
    <footer>
      <div className="w-full h-1 bg-neutral-1" />
      <Container className="py-42 md:py-56 grid md:grid-cols-[1fr_32.4rem] gap-48 md:gap-85">
        <div className="flex flex-wrap lg:flex-nowrap gap-24 md:gap-42">
          {menu &&
            menu.length > 0 &&
            menu.map((column, index) => (
              <div key={index}>
                <Column column={column} />
              </div>
            ))}
        </div>
        <div className="col-span-full md:col-span-1 pt-32 border-t border-neutral-2 md:border-none md:pt-0">
          <div className="flex justify-between items-center mb-16">
            <div className="text-sm font-extrabold">Kontakt</div>
            <Image width={20} height={20} src="/images/align-left.svg" alt="" />
          </div>
          <ul>
            {contactItems.map((item) => (
              <li key={item.text} className="mb-4">
                <ContactItem {...item} />
              </li>
            ))}
          </ul>
        </div>
      </Container>
      <div className="w-full h-1 bg-neutral-1" />
      <Container className="py-42 md:pt-52 md:pb-60 flex justify-between items-center flex-wrap gap-24">
        <div className="text-neutral-4 text-sm flex gap-12 items-center">
          <div>© WSC {year}</div>
          <div className="w-4 h-4 bg-neutral-4 rounded-full" />
          {additional_links
            ?.filter((item) => item)
            .map((item, index) => (
              <div
                key={index}
                className="transition-colors hover:text-neutral-6"
              >
                <Link href={item.link.url}>
                  <a
                    target={item.link.target}
                    dangerouslySetInnerHTML={{ __html: item.link.title }}
                  />
                </Link>
              </div>
            ))}
        </div>

        {socials && socials.length > 0 && (
          <div className="flex gap-16">
            {socials
              .filter((item) => item)
              .filter((social) =>
                ['facebook', 'youtube', 'linkedin', 'instagram'].some((name) =>
                  social.includes(name),
                ),
              )
              .map((social, index: number) => {
                return (
                  <a
                    key={index}
                    href={social}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="border border-transparent hover:bg-primary transition-colors"
                  >
                    {social.includes('facebook') && <FacebookIcon />}
                    {social.includes('youtube') && <YoutubeIcon />}
                    {social.includes('linkedin') && <LinkedinIcon />}
                    {social.includes('instagram') && <InstagramIcon />}
                  </a>
                );
              })}
          </div>
        )}
        <div className="flex items-center">
          <span className="text-neutral-4 text-sm mr-12">design&code</span>
          <a
            href="https://www.empressia.pl/"
            target="_blank"
            rel="noreferrer noopener"
            className="group"
          >
            <Image
              src={'/images/empressia.svg'}
              width={112}
              height={12}
              alt="Empressia"
              className="filter grayscale opacity-40 transition-all group-hover:opacity-100 group-hover:grayscale-0"
            />
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
