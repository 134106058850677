import { PropsWithChildren } from 'react';

export interface DropdownHeadingProps {
  heading?: string | undefined;
}

export const DropdownHeading = (
  props: PropsWithChildren<DropdownHeadingProps>,
) => {
  const { children } = props;
  return (
    <div>
      <p className="text-n-900 block p-12 text-sm font-bold">{children}</p>
    </div>
  );
};
