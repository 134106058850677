import { useQuery, UseQueryOptions } from 'react-query';
import { getSearch } from 'src/services/api/queries/getters/getSearch';
import { ISearchRequest } from 'src/services/api/types/ISearch';

export function useSearchQuery(
  params: ISearchRequest,
  options?: UseQueryOptions,
) {
  return useQuery<any>(...getSearch(params), options as any);
}
