export type Route = {
  [key: string]: {
    url: string;
  };
};

const routes: Route = {
  homepage: {
    url: '/',
  },
  blog: {
    url: '/wiadomosci',
  },
  blogCategory: {
    url: '/wiadomosci' + '/category/',
  },
  singlePosts: {
    url: '/wiadomosci/',
  },
  products: {
    url: '/produkty',
  },
  search: {
    url: '/szukaj',
  },
  events: {
    url: '/events',
  },
  realization: {
    url: '/realizacje',
  },
  publication: {
    url: '/publikacje',
  },
  product: {
    url: '/produkty',
  },
  event: {
    url: '/wydarzenia',
  },
  news: {
    url: '/aktualnosci',
  },
  knowledge: {
    url: '/baza-wiedzy',
  },
  knowledgeCycles: {
    url: '/baza-wiedzy' + '/cykle',
  },
  contact: {
    url: '/kontakt',
  },
  'archicad-diploma': {
    url: '/dyplom-z-archicadem',
  },
  support: {
    url: '/wsparcie-techniczne',
  },
  supportSearch: {
    url: '/wsparcie-techniczne/szukaj',
  },
};

export default routes;
