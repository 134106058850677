import { useEffect, useState } from 'react';

const useKeyDown = (targetKey = 'Escape') => {
  const [pressed, setKeyPressed] = useState<boolean>(false);

  useEffect(() => {
    const keyDownHandler = ({ key }: KeyboardEvent) =>
      key === targetKey && setKeyPressed(true);

    const keyUpHandler = ({ key }: KeyboardEvent) =>
      key === targetKey && setKeyPressed(false);

    window.addEventListener('keydown', keyDownHandler);
    window.addEventListener('keyup', keyUpHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
      window.removeEventListener('keyup', keyUpHandler);
    };
  }, [targetKey]);

  return pressed;
};

export default useKeyDown;
