import dynamic from 'next/dynamic';

const CtaBanner = dynamic(() => import('../views/CtaBanner'));
const MainSlider = dynamic(() => import('../containers/HeroCarousel'));
const SupportSection = dynamic(() => import('../containers/SupportSection'));
const ContactCtaSection = dynamic(
  () => import('src/components/containers/ContactCtaSection/ContactCtaSection'),
);
const ProductsCategoriesSection = dynamic(
  () => import('../views/ProductsCategoriesSection'),
);
const NewsSection = dynamic(() => import('../containers/NewsSection'));
const BenefitsSection = dynamic(() => import('../views/BenefitsSection'));
const MenuGrid = dynamic(
  () => import('../views/Menu/MegaMenu/organisms/MenuGrid'),
);
const ContentGroup = dynamic(
  () => import('../views/Menu/MegaMenu/molecules/ContentGroup'),
);
const ListGroup = dynamic(
  () => import('../views/Menu/MegaMenu/molecules/ListGroup'),
);
const WideCtaSection = dynamic(() => import('../views/WideCTA'));
const ProductHero = dynamic(
  () => import('src/components/containers/Hero/Hero'),
);
const ContentBox = dynamic(() => import('../views/ContentBox'));
const ContentBoxes = dynamic(() => import('../views/ContentBoxes'));
const TitleDescriptionSection = dynamic(
  () => import('../views/TitleDescriptionSection'),
);
const SecondaryMenu = dynamic(() => import('../containers/SecondaryMenu'));
const PostsBoxesSection = dynamic(() => import('../views/PostsBoxesSection'));
const DownloadSection = dynamic(() => import('../views/DownloadSection'));
const Heading = dynamic(() => import('../views/Heading'));
const SingleQuote = dynamic(() => import('../views/Quote/SingleQuote'));
const QuoteSection = dynamic(() => import('../views/Quote/QuoteSection'));
const AccordionGroup = dynamic(() => import('../views/AccordionGroup'));
const ImageBox = dynamic(() => import('../views/ImageBox'));
const CustomStyles = dynamic(() => import('../containers/CustomStyles'));
const Image = dynamic(() => import('../containers/Image'));
const ProductTilesGroupedByCategories = dynamic(
  () => import('../containers/ProductTilesGroupedByCategories'),
);
const ProductTilesGroupedByCategoriesSection = dynamic(
  () => import('../containers/ProductTilesGroupedByCategoriesSection'),
);
const GridImages = dynamic(() => import('../views/GridImages'));
const Button = dynamic(() => import('../containers/ButtonACF'));
const HiddenContentButton = dynamic(
  () => import('../containers/HiddenContentButton'),
);
const Decorator = dynamic(() => import('../containers/Decorator'));
const OurValueBox = dynamic(() => import('../views/OurValueBox'));
const HighlightedHeading = dynamic(() => import('../views/HighlightedHeading'));
const TeamSection = dynamic(() => import('../views/TeamSection'));
const FactSheetSection = dynamic(
  () => import('src/components/views/FactSheetSection/FactSheetSection'),
);
const SocialsSection = dynamic(() => import('../containers/SocialsSection'));
const HighlightedEvents = dynamic(
  () => import('../containers/Events/HighlightedEvents'),
);
const TableOfContents = dynamic(() => import('../views/TableOfContents'));
const Newsletter = dynamic(() => import('../containers/Newsletter'));
const BlogSlider = dynamic(() => import('../views/BlogSlider'));
const Chip = dynamic(() => import('../views/Chip'));
const GlossyButton = dynamic(() => import('../containers/GlossyButton'));
const ContainerWithGlossyButtons = dynamic(
  () => import('../containers/ContainerWithGlossyButtons'),
);
const ListACF = dynamic(() => import('../containers/ListACF'));
const MultitaskBlock = dynamic(() => import('../containers/MultitaskBlock'));
const Embed = dynamic(() => import('../views/Embed'));
const EventLegend = dynamic(() => import('../containers/Events/EventLegend'));
const CtaSimple = dynamic(() => import('../views/CtaSimple'));
const CoverImage = dynamic(() => import('../views/CoverImage'));
const NewsBox = dynamic(() => import('../containers/NewsBox'));
const Table = dynamic(() => import('../views/Table'));
const EventTile = dynamic(
  () => import('src/components/containers/EventTileAcf/EventTileAcf'),
);
const RealizationsMap = dynamic(() => import('../containers/RealizationsMap'));
const ButtonsGroup = dynamic(() => import('../views/ButtonsGroup'));
const DownloadTile = dynamic(() => import('../views/DownloadTile'));
const DocumentTile = dynamic(() => import('../views/DocumentTile'));
const ChevronBoxesWithImageSection = dynamic(
  () => import('../views/ChevronBoxesWithImageSection'),
);
const SimpleBox = dynamic(() => import('../views/SimpleBox'));
const HighlightSection = dynamic(() => import('../views/HighlightSection'));
const LightboxGallery = dynamic(() => import('../views/LightboxGallery'));
const Breadcrumb = dynamic(() => import('../views/Breadcrumbs'));
const AttachedLinks = dynamic(
  () => import('src/components/containers/AttachedLinksACF/AttachedLinksACF'),
);
const LatestKnowledgePosts = dynamic(
  () => import('../containers/LatestKnowledgePosts'),
);
const ContentSeparator = dynamic(
  () => import('../containers/ContentSeparator'),
);
const CookieButton = dynamic(() => import('../containers/CookieButton'));
const List = dynamic(() => import('../views/List'));
const Gallery = dynamic(() => import('../views/Gallery'));
const LatestEventsList = dynamic(() => import('../views/LatestEventsList'));
const BasicCarousel = dynamic(() => import('../views/BasicCarousel'));
const Paragraph = dynamic(() => import('../views/Paragraph'));
const StrokeTitleAcf = dynamic(() => import('../containers/StrokeTitleAcf'));
const AwardInfo = dynamic(() => import('../views/AwardInfo'));
const GallerySlider = dynamic(() => import('../views/GallerySlider'));
const FeaturedWork = dynamic(() => import('../views/FeaturedWork'));
const PreviousEditions = dynamic(() => import('../views/PreviousEditions'));
const FilesPreview = dynamic(() => import('../views/FilesPreview'));
const LatestFaqPosts = dynamic(() => import('../views/LatestFaqPosts'));
const HeadingCta = dynamic(() => import('../views/HeadingCta'));
const OurValuesSection = dynamic(
  () => import('../containers/OurValuesSection'),
);
const ArchicadDiplomaSecondaryMenu = dynamic(
  () => import('../containers/SecondaryMenu'),
);
const Form = dynamic(() => import('../containers/Form'));
const TilesModalSection = dynamic(() => import('../views/TilesModalSection'));
const ContactMapSection = dynamic(() => import('../views/ContactMapSection'));
const ImageLink = dynamic(() => import('../views/ImageLink'));
const ConferencesProgram = dynamic(
  () => import('../containers/ConferencesProgram'),
);
const LatestArchicadDiplomaNews = dynamic(
  () => import('../views/LatestArchicadDiplomaNews'),
);
const LatestPosts = dynamic(() => import('../views/LatestPosts'));
const ListItem = dynamic(() => import('../views/Core/ListItem'));

const LibrariesTabs = dynamic(() => import('../containers/LibrariesTabs'));

const ObjectsBrowser = dynamic(() => import('../containers/ObjectsBrowser'));

export {
  AccordionGroup,
  CtaBanner,
  ContentGroup,
  BenefitsSection,
  MainSlider as HeroCarousel,
  NewsSection,
  MenuGrid,
  ListGroup,
  EventLegend,
  ProductsCategoriesSection,
  SupportSection,
  ImageBox,
  WideCtaSection,
  ContactCtaSection,
  ContentBox,
  ContentBoxes,
  HighlightedHeading,
  TitleDescriptionSection,
  SecondaryMenu,
  PostsBoxesSection,
  ProductHero,
  Heading,
  SingleQuote,
  QuoteSection,
  CustomStyles,
  Image,
  ProductTilesGroupedByCategories,
  ProductTilesGroupedByCategoriesSection,
  GridImages,
  Button,
  HiddenContentButton,
  Decorator,
  OurValueBox,
  TeamSection,
  FactSheetSection,
  SocialsSection,
  HighlightedEvents,
  TableOfContents,
  Newsletter,
  BlogSlider,
  Chip,
  GlossyButton,
  ContainerWithGlossyButtons,
  ListACF as ListAcf,
  DownloadSection,
  MultitaskBlock,
  Embed,
  CtaSimple,
  CoverImage,
  NewsBox,
  Table,
  EventTile,
  RealizationsMap,
  DownloadTile,
  DocumentTile,
  ChevronBoxesWithImageSection,
  SimpleBox,
  HighlightSection,
  LightboxGallery,
  Breadcrumb,
  AttachedLinks,
  LatestKnowledgePosts,
  ContentSeparator,
  ButtonsGroup,
  CookieButton,
  List,
  Gallery,
  LatestEventsList,
  BasicCarousel,
  Paragraph,
  StrokeTitleAcf,
  AwardInfo,
  GallerySlider,
  FeaturedWork,
  PreviousEditions,
  FilesPreview,
  LatestFaqPosts,
  HeadingCta,
  OurValuesSection,
  ArchicadDiplomaSecondaryMenu,
  Form,
  TilesModalSection,
  ContactMapSection,
  ImageLink,
  ConferencesProgram,
  LatestArchicadDiplomaNews,
  LatestPosts,
  ListItem,
  LibrariesTabs,
  ObjectsBrowser,
};
