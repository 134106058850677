import React from 'react';
import clsx from 'clsx';
import Link from 'next/link';

export interface ColumnProps {
  column: {
    name: string;
    items: Array<{
      name: string;
      url: string;
      target?: string;
    }>;
  };
}

const Column: React.FunctionComponent<ColumnProps> = ({ column }) => {
  return (
    <div>
      <div
        className="text-sm font-extrabold mb-8"
        dangerouslySetInnerHTML={{ __html: column.name }}
      />
      <ul>
        {column.items
          .filter((item) => item.name && item.url)
          .map((item, index) => (
            <li
              key={`${item.name}-${index}`}
              className={clsx({
                'mb-4': index < column.items.length - 1,
              })}
            >
              <Link href={item.url}>
                <a
                  className="w-full block text-sm text-neutral-4 transition-colors hover:text-neutral-6"
                  target={item.target}
                  dangerouslySetInnerHTML={{ __html: item.name }}
                />
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};
export default Column;
