import { IMenu } from 'src/services/api/types/IMenu';

export const listToTree = (list: IMenu[]) => {
  const map: { [key: string]: number } = {};
  const roots = [];

  for (let i = 0; i < list.length; i++) {
    map[list[i].ID] = i;
    list[i].children = [];
  }

  for (let i = 0; i < list.length; i++) {
    const node = list[i];

    if (node.menu_item_parent !== '0') {
      list[map[node.menu_item_parent]].children.push(node);
    } else {
      roots.push(node);
    }
  }

  return roots;
};
