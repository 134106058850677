import Link from 'next/link';
import React, { FC } from 'react';

interface SearchLinkValue {
  href?: string;
  onClickHandler?: () => void;
}

const SearchLink: FC<SearchLinkValue> = ({
  href,
  children,
  onClickHandler,
}) => {
  if (onClickHandler) {
    return (
      <div
        className="text-primary py-4 block hover:bg-neutral-1 transition-colors -mx-8 px-8"
        onClick={onClickHandler}
        role="button"
      >
        {children}
      </div>
    );
  }

  if (href) {
    return (
      <Link href={href}>
        <a className="text-primary py-4 block hover:bg-neutral-1 transition-colors -mx-8 px-8">
          {children}
        </a>
      </Link>
    );
  }

  return null;
};

export default SearchLink;
