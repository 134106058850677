import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

type ScrollState = {
  isBelowRoot: boolean;
};

/**
 * Hook to observe if defined root margin has been passed
 * @param {number} rootMargin - The distance of top user's screen in px
 */
const useObserveScrollPosition = (rootMargin: number): ScrollState => {
  const [isBelowRoot, setIsBelowRoot] = useState<boolean>(false);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos > rootMargin) {
      setIsBelowRoot(true);
    } else {
      setIsBelowRoot(false);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return { isBelowRoot };
};
export default useObserveScrollPosition;
