import {
  createContext,
  Dispatch,
  FC,
  useCallback,
  useContext,
  useReducer,
} from 'react';

type CookiesActionKind =
  | 'SET_CONSENTS'
  | 'DECLINE_CONSENTS'
  | 'HIDE_COOKIES_BAR'
  | 'SHOW_COOKIES_BAR'
  | 'TOGGLE_COOKIES_BAR'
  | 'COOKIE_DIALOG_LOADING'
  | 'COOKIE_DIALOG_NOT_LOADING';

export type ConsentValue = {
  stamp?: string;
  marketing: boolean;
  necessary: boolean;
  statiscics: boolean;
  preferences: boolean;
};

type CookiesActions = {
  type: CookiesActionKind;
  payload?: ConsentValue;
};

type CookiesActionTypes = CookiesActions;

export type CookiesState = {
  consent: NonNullable<ConsentValue>;
  isCookiesBarShown: boolean;
  isCookiesDialogLoading: boolean;
};

export type CookiesContextType = {
  cookies: CookiesState;
  dispatchCookies: Dispatch<CookiesActionTypes>;
};

const initialConsent: ConsentValue = {
  marketing: false,
  necessary: false,
  statiscics: false,
  preferences: false,
};

function cookiesReducer(
  state: CookiesState,
  action: CookiesActionTypes,
): CookiesState {
  const { type, payload } = action;

  switch (type) {
    case 'SET_CONSENTS':
      return {
        ...state,
        consent: payload as ConsentValue,
      };
    case 'DECLINE_CONSENTS':
      return {
        ...state,
        consent: {
          ...initialConsent,
        },
      };
    case 'HIDE_COOKIES_BAR':
      return { ...state, isCookiesBarShown: false };
    case 'SHOW_COOKIES_BAR':
      return { ...state, isCookiesBarShown: true };
    case 'TOGGLE_COOKIES_BAR':
      return { ...state, isCookiesBarShown: !state.isCookiesBarShown };
    case 'COOKIE_DIALOG_LOADING':
      return { ...state, isCookiesDialogLoading: true };
    case 'COOKIE_DIALOG_NOT_LOADING':
      return { ...state, isCookiesDialogLoading: false };
    default:
      return state;
  }
}

const CookiesContext = createContext<CookiesContextType | undefined>(undefined);

const CookiesProvider: FC = ({ children }) => {
  const [cookies, dispatchCookies] = useReducer(cookiesReducer, {
    isCookiesBarShown: false,
    isCookiesDialogLoading: false,
    consent: {
      ...initialConsent,
    },
  });

  return (
    <CookiesContext.Provider
      value={{
        cookies,
        dispatchCookies,
      }}
    >
      {children}
    </CookiesContext.Provider>
  );
};

const useCookiesContext = () => {
  const ctx = useContext(CookiesContext);

  if (ctx === undefined) {
    throw new Error('useCookiesContext must be used within a CookiesProvider');
  }

  const { dispatchCookies: dispatch } = ctx;

  const setConsent = useCallback(
    (payload: ConsentValue) => {
      dispatch({ type: 'SET_CONSENTS', payload });
    },
    [dispatch],
  );

  const declineConsent = useCallback(() => {
    dispatch({ type: 'DECLINE_CONSENTS' });
  }, [dispatch]);

  const hideCookiesBar = () => {
    dispatch({ type: 'HIDE_COOKIES_BAR' });
  };

  const showCookiesBar = () => {
    dispatch({ type: 'SHOW_COOKIES_BAR' });
  };

  const toggleCookiesBar = () => {
    dispatch({ type: 'TOGGLE_COOKIES_BAR' });
  };

  const cookiesDialogLoading = (isLoading: boolean) => {
    if (isLoading) {
      dispatch({ type: 'COOKIE_DIALOG_LOADING' });
    }

    if (!isLoading) {
      dispatch({ type: 'COOKIE_DIALOG_NOT_LOADING' });
    }
  };

  return {
    cookies: ctx.cookies,
    set: dispatch,
    setConsent,
    declineConsent,
    hideCookiesBar,
    showCookiesBar,
    toggleCookiesBar,
    cookiesDialogLoading,
  };
};

export { CookiesProvider, useCookiesContext };
