import 'tippy.js/animations/shift-away.css';
import Button, { ButtonProps } from '../Button';
import React, {
  ComponentProps,
  forwardRef,
  Ref,
  useImperativeHandle,
  useState,
} from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import { DropdownContent } from './DropdownContent';
import { DropdownHeadingProps } from './DropdownHeading';
import { DropdownItem } from './DropdownItem';
import ChevronDownIcon from '../../../assets/images/icons/chevron-down.svg';

type DropdownButtonConfig = ButtonProps & {
  label: string;
};
export interface DropdownButtonProps {
  button: DropdownButtonConfig;
  items: any[];
  tippyConfig?: TippyProps;
  ItemComponent?: (args: ComponentProps<any>) => JSX.Element;
  headingLabel?: DropdownHeadingProps['heading'];
}

export interface DropdownItemProps {
  label: string;
  onClickHandler?: () => void;
  href?: string;
}

const defaultConfig: TippyProps = {
  interactive: true,
  placement: 'bottom',
  zIndex: 10,
};

const defaultButton: Omit<DropdownButtonConfig, 'label'> = {
  variant: 'DROPDOWN',
  icon: {
    iconComponent: <ChevronDownIcon fill="currentCurrent" />,
  },
};

export type CloseDropdownHandler = { hideDropdown: () => void };

const DropdownButton = forwardRef(
  (props: DropdownButtonProps, ref: Ref<CloseDropdownHandler>) => {
    const [visible, setVisible] = useState(false);

    const { button, items, headingLabel, tippyConfig, ItemComponent } = props;

    const toggleVisibleDropdown = () => setVisible((prevState) => !prevState);
    const hideDropdown = () => setVisible(false);

    useImperativeHandle(ref, () => ({
      hideDropdown,
    }));

    if (!items) return null;

    return (
      <Tippy
        content={
          <DropdownContent
            items={items}
            renderInner={(args) => {
              return (
                <div className="py-1">
                  {ItemComponent ? (
                    <ItemComponent {...args} />
                  ) : (
                    <DropdownItem {...args} />
                  )}
                </div>
              );
            }}
            heading={headingLabel}
          />
        }
        visible={visible}
        onClickOutside={hideDropdown}
        {...defaultConfig}
        {...tippyConfig}
        animation="shift-away"
      >
        {/* //div wrapper is needed to proper working Tippy */}
        <div>
          <Button
            {...defaultButton}
            {...button}
            onClick={toggleVisibleDropdown}
            noPadding
          >
            {button.label}
          </Button>
        </div>
      </Tippy>
    );
  },
);

export default DropdownButton;
