export const getUrlFromString = (text: string) => {
  const value = text.match(/"(http|https)[^"]*"/);
  return value ? value[0].replace(/"/g, '') : '';
};

export const getTextBetweenFigcaption = (content: string) => {
  const match = content.match(/(<figcaption>)(.*?)(<\/figcaption>)/);
  return match && match[2] ? match[2] : '';
};

export const getClassNameFromString = (text: string) => {
  const value = text.match(/class="(.*?)"/);
  return value && value.length >= 1 ? value[1] : '';
};

export const getImageAlt = (content: string) => {
  const match = content.match(/(alt=")(.*?)(")/);
  return match && match[2] ? match[2] : '';
};
