export const GET_HOMEPAGE = 'GET_HOMEPAGE';
export const GET_MENUS = 'GET_MENUS';
export const GET_OPTIONS = 'GET_OPTIONS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_BLOG_PAGE = 'GET_BLOG_PAGE';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_EVENT = 'GET_EVENT';
export const GET_GRAVITY_FORM = 'GET_GRAVITY_FORM';
export const GET_FOOTER = 'GET_FOOTER';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT_ARCHIVE = 'GET_PRODUCT_ARCHIVE';
export const GET_PAGE_FROM_SLUG = 'GET_PAGE_FROM_SLUG';
export const GET_PRODUCT_PAGE = 'GET_PRODUCT_PAGE';
export const GET_PRODUCT_FILTERS = 'GET_PRODUCT_FILTERS';
export const GET_EVENTS_FILTERS = 'GET_EVENTS_FILTERS';
export const GET_POST_TYPE_COLLECTION = 'GET_POST_TYPE_COLLECTION';
export const GET_GROUP_SEARCH = 'GET_GROUP_SEARCH';
export const GET_POST_TYPE = 'GET_POST_TYPE';
export const GET_REALIZATIONS = 'GET_REALIZATIONS';
export const GET_REALIZATIONS_PAGE = 'GET_REALIZATIONS_PAGE';
export const GET_EVENTS_PAGE = 'GET_EVENTS_PAGE';
export const GET_AGGREGATE_POSTS = 'GET_AGGREGATE_POSTS';
export const GET_NEWS_PAGE = 'GET_NEWS_PAGE';
export const GET_PRESS_RELEASES_PAGE = 'GET_PRESS_RELEASES_PAGE';
export const GET_BLOCK = 'GET_BLOCK';
export const GET_SIDEBLOCK_PAGE = 'GET_SIDEBLOCK_PAGE';
export const GET_PUBLICATION_ARCHIVE = 'GET_REALIZATIONS_PAGE';
export const GET_PUBLICATIONS = 'GET_PUBLICATIONS';
export const GET_SEARCH = 'GET_SEARCH';
export const GET_SEARCH_PAGE = 'GET_SEARCH_PAGE';
export const GET_PRODUCTS_PAGE = 'GET_PRODUCTS_PAGE';
export const GET_AGGREGATE_POSTS_WITH_TYPES = 'GET_AGGREGATE_POSTS_WITH_TYPES';
export const GET_KNOWLEDGE_POSTS = 'GET_KNOWLEDGE_POSTS';
export const GET_KNOWLEDGE_PAGE = 'GET_KNOWLEDGE_PAGE';
export const GET_SUPPORT_PAGE = 'GET_SUPPORT_PAGE';
export const GET_TECHNICAL_SUPPORT_PAGE = 'GET_TECHNICAL_SUPPORT_PAGE';
export const GET_TECHNICAL_SUPPORT = 'GET_TECHNICAL_SUPPORT';
export const GET_GOOGLE_GEOCODE_REVERSE = 'GET_GOOGLE_GEOCODE_REVERSE';
export const GET_CONTACT_PAGE = 'GET_CONTACT_PAGE';
export const GET_ARCHICAD_DIPLOMA_PAGE = 'GET_ARCHICAD_DIPLOMA_PAGE';
export const GET_CONTACT_PAGES = 'GET_CONTACT_PAGES';
export const GET_CITY_BY_ZIP = 'GET_CITY_BY_ZIP';
export const OPEN_STREET_MAP_GEOCODING_REVERSE =
  'OPEN_STREET_MAP_GEOCODING_REVERSE';
export const GET_FINANCING_PAGE = 'GET_FINANCING_PAGE';
export const GET_RODO_PAGE = 'GET_RODO_PAGE';
export const GET_ARCHICLUB_ARCHIVE_PAGE = 'GET_ARCHICLUB_ARCHIVE_PAGE';
export const GET_PRIVACY_POLICY_PAGE = 'GET_PRIVACY_POLICY_PAGE';
