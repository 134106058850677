import { createContext, FC, useContext } from 'react';

export type ProductColors = {
  main?: string | null;
  washed?: string | null;
};

export type ConferenceColors = {
  primary?: string | null;
  secondary?: string | null;
  tertiary?: string | null;
};

export type ColorsContextState = {
  productColors: ProductColors;
  conferenceColors?: ConferenceColors;
};

const ColorsContext = createContext<ColorsContextState | undefined>(undefined);

const ColorsProvider: FC<ColorsContextState> = ({
  children,
  productColors,
  conferenceColors,
}) => {
  return (
    <ColorsContext.Provider value={{ productColors, conferenceColors }}>
      {children}
    </ColorsContext.Provider>
  );
};

const useColors = () => {
  const ctx = useContext(ColorsContext);

  if (ctx === undefined) {
    throw new Error('useColors must be used within a ColorsProvider');
  }

  return ctx;
};

export { ColorsProvider, useColors };
