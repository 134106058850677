import { IApi } from '../types/IApi';
import axiosInstance from './axiosInstance';
import {
  ISearchRequest,
  ISearchResponse,
} from 'src/services/api/types/ISearch';

/**
 * {@link https://developer.wordpress.org/rest-api/reference/search-results}
 */
class SearchApi implements IApi {
  basePath = 'wp/v2/search';

  async get(args: ISearchRequest) {
    return axiosInstance.get<ISearchResponse[]>(this.basePath, {
      params: args,
    });
  }
}

export default SearchApi;
