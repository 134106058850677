import { DropdownItemProps } from '.';

export const DropdownItem = (props: DropdownItemProps) => {
  const { label, onClickHandler } = props;
  return (
    <button
      className="text-gray-700 block px-12 py-4 text-sm"
      role="menuitem"
      onClick={onClickHandler}
    >
      <span dangerouslySetInnerHTML={{ __html: label }} />
    </button>
  );
};
