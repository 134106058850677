import React from 'react';
import styled from 'styled-components';
import { theme } from '@styles/theme';
import InformationTriangle from '../../../assets/images/icons/information-triangle.svg';
import clsx from 'clsx';

export interface HighlightSectionProps {
  variant: 'warning' | 'success' | 'informative';
  title: string;
  withIcon?: boolean;
}

const IconWrapper = styled.span<{
  variant: HighlightSectionProps['variant'];
}>`
  path {
    fill: ${({ variant }) => theme.colors.denotive?.[variant]?.[900]};
  }
`;

const StyledWrapper = styled.div`
  .highlightSection {
    li {
      font-size: 1.6rem;
    }
  }
`;

const HighlightSection: React.FunctionComponent<HighlightSectionProps> = ({
  variant = 'informative',
  title,
  withIcon,
  children,
}) => {
  const colors = theme.colors.denotive?.[variant];

  return (
    <StyledWrapper
      className={clsx('px-12 py-24 sm:p-24 gap-18 relative', {
        'grid md:grid-cols-[1.7rem_1fr]': withIcon,
      })}
      style={{ backgroundColor: colors?.[100] }}
    >
      <div
        className="absolute top-0 left-0 h-full w-4"
        style={{ backgroundColor: colors?.[900] }}
      />
      {withIcon && (
        <IconWrapper variant={variant} className="pt-[0.5rem]">
          <InformationTriangle />
        </IconWrapper>
      )}
      <div>
        <div className="font-extrabold mb-8" style={{ color: colors?.[900] }}>
          {title}
        </div>
        {children}
      </div>
    </StyledWrapper>
  );
};
export default HighlightSection;
