import { useCallback, useState } from 'react';

export type MenuStateHandlerType = () => void;
export type MenuActiveItemHandlerType = (index: number | undefined) => void;
export type MenuStateValue = number | undefined;

const useOpenMenu = () => {
  const [openedMenu, setOpenedMenu] = useState<MenuStateValue>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openMegaMenuHandler: MenuActiveItemHandlerType = (index) =>
    setOpenedMenu(index);
  const closeMegaMenuHandler: MenuActiveItemHandlerType = () =>
    setOpenedMenu(undefined);

  const toggleOpenMenu: MenuStateHandlerType = useCallback(() => {
    if (isOpen) {
      closeMegaMenuHandler(undefined);
    }

    setIsOpen((prevState) => !prevState);
  }, [isOpen, setIsOpen]);

  const closeMenu = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    toggleOpenMenu,
    openedMenu,
    openMegaMenuHandler,
    closeMegaMenuHandler,
    closeMenu,
  };
};
export default useOpenMenu;
