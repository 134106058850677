import { convertInlineStylesToObject } from './convertInlineStylesToObject';

export const getHtmlAttributesFromString = (searchString: string) => {
  const result: { [key: string]: string } = {};
  const reg = /\s(\w+?)="(.+?)"/g;
  const res = reg.exec(searchString);

  const style = convertInlineStylesToObject(searchString);

  if (res && res.length >= 2) {
    const [, name, value] = res;
    result[name === 'class' ? 'className' : name] = value;
  }

  return Object.assign(result, {
    style,
  });
};
