import { GET_SEARCH } from './../../../CONSTANTS';
import { QueryTuple } from './../types';
import SearchApi from 'src/services/api/wp/SearchApi';
import { ISearchRequest } from 'src/services/api/types/ISearch';

const getSearchTuple = (params?: ISearchRequest) => [GET_SEARCH, params];

const getSearch = (params: ISearchRequest): QueryTuple => {
  return [getSearchTuple(params), async () => new SearchApi().get(params)];
};

export { getSearch, getSearchTuple };
