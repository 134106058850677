import React from 'react';
import clsx from 'clsx';

const ContactItem: React.FunctionComponent<{ text: string; link?: string }> = ({
  text,
  link,
}) => {
  const Inner = (
    <span
      className={clsx('w-full block text-sm text-neutral-4 transition-colors', {
        'hover:text-neutral-6': !!link,
      })}
    >
      {text}
    </span>
  );

  if (link) {
    return <a href={link}>{Inner}</a>;
  }

  return Inner;
};

export default ContactItem;
