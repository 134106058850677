import { useEffect } from 'react';
import { useCookiesContext } from 'src/context/cookies.context';
import { removeCookie } from 'src/utils/cookies';
import { RecentSearchCookieName } from 'src/constants/cookiesConstants';

const necessaryCookiesList = [RecentSearchCookieName];

const useWatchForRemoveCookies = () => {
  const {
    cookies: { consent },
  } = useCookiesContext();

  useEffect(() => {
    !consent.necessary &&
      necessaryCookiesList.forEach((cookie) => removeCookie(cookie));
  }, [consent]);
};

export default useWatchForRemoveCookies;
