import Container from '@components/containers/Container';
import LanguageSwitch from '@components/containers/LanguageSwitch';
import React from 'react';
import Announcement from './Announcement';
import SearchIcon from '../../../assets/images/icons/Search.svg';
import SearchContainer from '@components/containers/SearchContainer';
import { useAppContext } from 'src/context/app.context';
import clsx from 'clsx';
import { useColors } from 'src/context/colors.context';

interface TopBarProps {
  announcement?: string;
  isMultilingualEnabled?: boolean;
}

const TopBar: React.FunctionComponent<TopBarProps> = ({
  announcement,
  isMultilingualEnabled,
}) => {
  const { handleSearchVisible } = useAppContext();
  const { productColors } = useColors();

  return (
    <>
      <div
        id="announcement"
        className="bg-neutral-1 py-12 text-xs relative z-40"
        style={{ backgroundColor: productColors.washed || '' }}
      >
        <Container className="flex justify-between items-center relative">
          {announcement && (
            <div className="pr-8">
              <Announcement announcement={announcement} />
            </div>
          )}
          <button
            id="magnifier"
            onClick={handleSearchVisible}
            className={clsx('ml-auto flex-shrink-0', {
              'mr-12': isMultilingualEnabled,
            })}
          >
            <SearchIcon />
          </button>
          <div>{isMultilingualEnabled && <LanguageSwitch />}</div>
        </Container>
      </div>
      <SearchContainer />
    </>
  );
};

export default TopBar;
