import React from 'react';
import Badge from '@components/views/Badge';
import omit from 'lodash/omit';
import { IBadge } from '@components/containers/SearchContainer';

export interface SearchBadgeProps {
  badge: IBadge;
  handleAddBadge: () => void;
  handleRemoveBadge: () => void;
  isActive: boolean;
}

const SearchBadge: React.FunctionComponent<SearchBadgeProps> = ({
  badge,
  handleAddBadge,
  handleRemoveBadge,
  isActive,
}) => {
  return (
    <button
      type="button"
      onClick={() => (!isActive ? handleAddBadge() : handleRemoveBadge())}
    >
      <Badge
        variant={isActive ? 'primary' : 'default'}
        {...omit(badge, ['name', 'id'])}
      >
        {badge.name}
      </Badge>
    </button>
  );
};
export default SearchBadge;
