import { createContext, FC, useContext, useState } from 'react';
import { IImageAcf } from 'src/types/IImageAcf';

export type AppContext = {
  yoastHeadJson: string;
  isSearchVisible: boolean;
  handleSearchVisible: () => void;
  handleSearchHidden: () => void;
  conferenceLogo?: IImageAcf;
  permalink?: string;
};

const AppContext = createContext<AppContext | undefined>(undefined);

const AppProvider: FC<{
  yoastHeadJson: AppContext['yoastHeadJson'];
  conferenceLogo?: IImageAcf;
  permalink?: AppContext['permalink'];
}> = ({ children, yoastHeadJson, conferenceLogo, permalink }) => {
  const [isSearchVisible, setSearchVisible] = useState(false);

  const handleSearchVisible = () => setSearchVisible(true);
  const handleSearchHidden = () => setSearchVisible(false);

  return (
    <AppContext.Provider
      value={{
        yoastHeadJson,
        isSearchVisible,
        handleSearchVisible,
        handleSearchHidden,
        conferenceLogo,
        permalink,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const ctx = useContext(AppContext);

  if (ctx === undefined) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return ctx;
};

export { AppProvider, useAppContext };
