import * as gutenbergBlocks from '@components/gutenbergBlocks';
import Container from '../Container';
import React, { Fragment } from 'react';
import clsx from 'clsx';
import pascalCase from 'pascalcase';
import { IBlockAttributes, IBlocks } from 'src/types/IBlocks';
import { convertGutenbergClassToTailwind } from 'src/utils/convertGutenbergClassToTailwind';
import { getClassNameFromString, getUrlFromString } from 'src/utils/urlRegex';
import { getHtmlAttributesFromString } from 'src/utils/getHtmlAttributesFromString';
import HighlightSection, {
  HighlightSectionProps,
} from '@components/views/HighlightSection';
import Gallery from '@components/views/Gallery';
import ListItem from '@components/views/Core/ListItem';
import { removeTags } from 'src/utils/removeTags';

export interface BlocksProps {
  blocks: IBlocks;
  colors?: {
    main: string;
    washed: string;
  };
}

const getBlockWidthClass = (className: string) => {
  const isNarrowClass = 'container--narrow';
  const isWideClass = 'container--wide';
  const isNarrow = className.includes(isNarrowClass);
  const isWide = className.includes(isWideClass);

  if (isNarrow) {
    return isNarrowClass;
  }

  if (isWide) {
    return isWideClass;
  }

  return '';
};

const CORE_REUSABLE_BLOCK = 'Block';

const renderList = (props: any) => {
  const { innerBlocks, attrs, innerHTML } = props;
  const { ordered, epGeneratedClass: className, type } = attrs;
  const isListItem = props.blockName === 'core/list-item';
  const Tag = isListItem ? 'li' : ordered ? 'ol' : 'ul';

  if (innerBlocks && innerBlocks.length > 0) {
    return (
      <Tag className={className} type={type}>
        {innerBlocks.map((block: any, itemIndex: number) => {
          const { innerBlocks: blocks } = block;

          if (blocks && blocks.length > 0) {
            return (
              <Fragment key={itemIndex}>
                {removeTags(innerHTML).trim()}
                {renderList(block)}
              </Fragment>
            );
          }

          return <ListItem key={itemIndex} {...block} />;
        })}
      </Tag>
    );
  }
  return <ListItem {...props} />;
};

const definePathArray = (blockName: string) =>
  blockName.split('/').map((block: string) => pascalCase(block));

const getComponentName = (pathArray: string[]) =>
  pascalCase(pathArray[pathArray.length - 1]);

const renderBlock = (
  componentName: string,
  attrs: IBlockAttributes,
  index: number,
  innerHTML: string,
) => {
  const Component = gutenbergBlocks[
    componentName as keyof typeof gutenbergBlocks
  ] as React.FC<any> | undefined;
  const className = attrs.core
    ? convertGutenbergClassToTailwind(attrs as any) + ' core'
    : convertGutenbergClassToTailwind(attrs as any);

  return Component ? (
    <Component
      {...attrs}
      {...attrs.data}
      className={className}
      innerHTML={innerHTML}
      key={index}
    />
  ) : (
    <div
      className={className}
      key={index}
      dangerouslySetInnerHTML={{ __html: innerHTML }}
    />
  );
};

const InnerGroup = (props: { blocks: any; isContainer: boolean }) => {
  const { blocks, isContainer, ...restProps } = props;

  return (
    <div {...(!isContainer && { ...restProps })}>{renderBlocks(blocks)}</div>
  );
};

const renderGroup = (
  blocks: any,
  index: number,
  innerHTML: string,
  attrs: any,
  colors: BlocksProps['colors'],
) => {
  let props: any = getHtmlAttributesFromString(innerHTML);
  const tailwindClasses = convertGutenbergClassToTailwind(attrs as any);
  let className = `${props.className} ${tailwindClasses}` || '';
  const propsClassName = className;
  const isContainer =
    className.includes('container') || attrs?.className?.includes('container');

  const isSticky = className.includes('sticky');
  const isMainColor = className.includes('color-main') ? 'main' : '';
  const isWashedColor = className.includes('color-washed') ? 'washed' : '';
  const isHighlight = className.includes('highlightSection');
  const { positionType, alwaysSticky, topValue } = attrs;

  let backgroundColor = '';
  if (isMainColor) backgroundColor = colors?.main || '';
  if (isWashedColor) backgroundColor = colors?.washed || '';

  className = className.replace(new RegExp('container', 'g'), '');

  props = {
    ...props,
    className,
    style: {
      ...props.style,
      backgroundColor,
    },
  };

  if (isHighlight) {
    const variants: HighlightSectionProps['variant'][] = [
      'warning',
      'informative',
      'success',
    ];
    let chosenVariant = '' as HighlightSectionProps['variant'];
    variants.forEach((variant) => {
      if (!chosenVariant && props.className.includes(variant)) {
        chosenVariant = variant;
      }
    });
    const withIcon = props.className.includes('withIcon');

    return (
      <div {...props}>
        {/*TODO: get title and withIcon prop from WP*/}
        <HighlightSection
          title="awdada"
          variant={chosenVariant || 'warning'}
          withIcon={withIcon}
        >
          <InnerGroup isContainer={isContainer} blocks={blocks} {...props} />
        </HighlightSection>
      </div>
    );
  }

  return isContainer ? (
    <Container
      key={index}
      className={`group-container ${getBlockWidthClass(propsClassName)}`}
    >
      <div {...props}>
        <InnerGroup isContainer={isContainer} blocks={blocks} {...props} />
      </div>
    </Container>
  ) : (
    <div
      className={clsx('w-full', {
        relative: !isSticky,
        'z-20': isSticky || positionType === 'sticky',
        'relative md:sticky md:top-100 self-start md:z-20': isSticky,
        sticky: positionType === 'sticky' && alwaysSticky,
        'relative md:sticky md:top-100':
          positionType === 'sticky' && !alwaysSticky,
      })}
      style={{
        ...(+topValue && positionType === 'sticky' && { top: +topValue || 0 }),
      }}
      key={index}
    >
      <InnerGroup isContainer={isContainer} blocks={blocks} {...props} />
    </div>
  );
};

const renderBlocks = (
  blocks: BlocksProps['blocks'],
  colors?: BlocksProps['colors'],
): any => {
  return blocks
    .filter(({ blockName }: { blockName: string }) => blockName)
    .map((block: any, index: number) => {
      const { blockName, innerHTML, attrs, innerBlocks } = block;
      const pathArray = definePathArray(blockName);
      const componentName = getComponentName(pathArray);

      if (blockName === 'core/image') {
        block.attrs.image = {
          url: getUrlFromString(innerHTML),
          width: attrs.width,
          height: attrs.height,
          align: attrs.align,
        };
        block.attrs.className = getClassNameFromString(innerHTML);
      }

      if (blockName === 'core/list') {
        return renderList(block);
      }

      if (blockName.includes('core') && blockName !== 'core/image') {
        block.attrs.core = true;
      }

      if (componentName === CORE_REUSABLE_BLOCK) {
        return renderBlocks(attrs, colors);
      }

      if (blockName === 'core/gallery') {
        return (
          <Gallery {...block} key={index}>
            {renderGroup(innerBlocks, index, innerHTML, attrs, colors)}
          </Gallery>
        );
      }

      if (Array.isArray(innerBlocks) && innerBlocks.length > 0) {
        return renderGroup(innerBlocks, index, innerHTML, attrs, colors);
      } else {
        return renderBlock(componentName, attrs, index, innerHTML);
      }
    });
};

const Blocks = ({ blocks, colors }: BlocksProps) => {
  return <>{renderBlocks(blocks, colors)}</>;
};

export default Blocks;
