import { createContext, FC } from 'react';
import useOpenMenu, {
  MenuActiveItemHandlerType,
  MenuStateHandlerType,
  MenuStateValue,
} from '../hooks/useOpenMenu';

interface MenuContextSchema {
  isOpen: boolean;
  toggleOpenMenu: MenuStateHandlerType;
  openedMenu: MenuStateValue;
  closeMegaMenuHandler: MenuActiveItemHandlerType;
  openMegaMenuHandler: MenuActiveItemHandlerType;
  closeMenu: MenuStateHandlerType;
}

const MenuContext = createContext<MenuContextSchema>({
  isOpen: false,
  toggleOpenMenu: () => null,
  openedMenu: undefined,
  closeMegaMenuHandler: () => null,
  openMegaMenuHandler: () => null,
  closeMenu: () => null,
});

const MenuContextProvider: FC = ({ children }) => {
  const {
    isOpen,
    toggleOpenMenu,
    openedMenu,
    closeMegaMenuHandler,
    openMegaMenuHandler,
    closeMenu,
  } = useOpenMenu();

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        toggleOpenMenu,
        openedMenu,
        closeMegaMenuHandler,
        openMegaMenuHandler,
        closeMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
export { MenuContextProvider, MenuContext };
