import React from 'react';
import parseStringToHTML from 'html-react-parser';
export interface ListItemProps {
  innerHTML: string;
}

const ListItem: React.FunctionComponent<ListItemProps> = (props) => {
  const { innerHTML } = props;
  return <>{parseStringToHTML(innerHTML)}</>;
};

export default ListItem;
