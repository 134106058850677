import React from 'react';
import AnnouncementStyled from './Announcement.styles';

export interface AnnouncementProps {
  announcement: string;
}

/**
 *
 * @link https://www.figma.com/file/bEoq4a8N0Yy6hvVvwAQxiS/WSC-%3A%3A-UI-Design?node-id=25%3A2608
 */
const Announcement: React.FunctionComponent<AnnouncementProps> = ({
  announcement,
}) => {
  return (
    <AnnouncementStyled
      dangerouslySetInnerHTML={{
        __html: announcement,
      }}
    />
  );
};

export default Announcement;
