import { mediaQueries, theme } from './theme';
import { createGlobalStyle } from 'styled-components';
import nProgressStyles from '@styles/vendor/nprogress';
import { defaultStyles } from '@styles/defaults/defaultStyles';

const GlobalStyle = createGlobalStyle`
  ${nProgressStyles};
  html {
    overflow-x: hidden;
    font-size: 62.5%; // 1rem === 10px
    scroll-behavior: smooth;
    scroll-padding-top: 20rem;
  }

  body {
    font-size: 1.6rem;
    font-family: ${theme.fontFamily.base};

    ${defaultStyles};
  }

  body:not(.is-cookie-dialog-loaded) #CybotCookiebotDialog {
    visibility: hidden;
  }

  .overflow-y-auto, .overflow-auto, .overflow-x-auto {
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: ${theme.colors.neutral[4]} transparent;
    };   /* scroll thumb and track */

    ::-webkit-scrollbar {
      height: 6px;
      width: 6px; /* width of the entire scrollbar */
    }

    ::-webkit-scrollbar-track {
      background: transparent; /* color of the tracking area */
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px; /* roundness of the scroll thumb */
      background-color: ${
        theme.colors.neutral[4]
      }; /* color of the scroll thumb */
    }

  :root {
    --container-margins: 3.4rem;
    --container-width: calc(100% - var(--container-margins));

    /** https://fkhadra.github.io/react-toastify/how-to-style */
    --toastify-color-success: ${theme.colors.denotive.success[50]};

    ${mediaQueries.greaterThan('xl')`
        --container-width: 116.8rem;
    `}
  }

  .wp-block-image, .wp-block-columns {
    margin-bottom: 0;
  }

  .wp-block-columns > .core {
    width: 100%;
  }

  .outer-right {
    ${mediaQueries.greaterThan('xl')`
        margin-right: calc( (100vw - var(--container-width)) / -2);
        max-width: var(--container-width);
    `}
  }

  .outer-tile {
    ${mediaQueries.lessThan('sm')`
      & {
        padding-left: calc( ( 100% - var(--container-width)) / 2);
        padding-right: calc( ( 100% - var(--container-width)) / 2);
        position: relative;
        width: calc(var(--container-width) + var(--container-margins) * 2);
        margin-left: calc( (var(--container-width) - 100%) / 2);
      }
    `}
  }

  .gm-style {
    img[alt="Google"] {
      display: none;
    }

    .gmnoprint {
      display: none;
    }
  }

  .grecaptcha-badge{
    visibility: hidden;
  }
`;

export default GlobalStyle;
