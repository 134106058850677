import SearchLink from '@components/views/SearchLink';
import SmallHeading from '@components/views/SmallHeading';
import { useRouter } from 'next/router';
import { useCookiesContext } from 'src/context/cookies.context';
import useGetRecentSearchParams from 'src/hooks/useGetRecentSearchParams';
import { getSearchRouterParams } from 'src/utils/search';

interface RecentSearchQueriesProps {
  onClickHandler?: () => void;
}

const RecentSearchQueries = ({ onClickHandler }: RecentSearchQueriesProps) => {
  const itemsCollection = useGetRecentSearchParams();
  const {
    cookies: { consent },
  } = useCookiesContext();

  const router = useRouter();
  if (
    !Array.isArray(itemsCollection) ||
    (Array.isArray(itemsCollection) && !itemsCollection.length) ||
    !consent.necessary
  )
    return null;

  return (
    <>
      <div className="mb-12 ">
        <SmallHeading label="ostatnio wyszukiwane" />
      </div>

      <ul>
        {itemsCollection.map(
          (item) =>
            item.search && (
              <li key={JSON.stringify(item)}>
                <SearchLink
                  onClickHandler={() => {
                    router.push(getSearchRouterParams(item));
                    onClickHandler && onClickHandler();
                    return;
                  }}
                >
                  <span>{item.search}</span>
                </SearchLink>
              </li>
            ),
        )}
      </ul>
    </>
  );
};

export default RecentSearchQueries;
