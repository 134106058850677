export const handleSpinnerPosition = () => {
  const announcement = document.getElementById('announcement');
  const nprogressSpinner = document.querySelector(
    '#nprogress .spinner',
  ) as HTMLDivElement;

  if (announcement && nprogressSpinner) {
    const { height: announcementHeight } = announcement.getBoundingClientRect();
    const { height: spinnerHeight } = nprogressSpinner.getBoundingClientRect();

    nprogressSpinner.style.top = `${
      announcementHeight / 2 - spinnerHeight / 2
    }px`;
  }
};
