import styled, { css } from 'styled-components';
import { theme } from '@styles/theme';

const EditorWrapper = styled.div<{ isPage?: boolean }>`
  color: ${theme.colors.neutral[900]};

  .wp-block-image,
  .coreEmbed {
    ${({ isPage }) =>
      !isPage
        ? css`
            margin: 4rem auto;
            max-width: var(--container-width);

            @media screen and (min-width: 991px) {
              max-width: 76.8rem;
            }
          `
        : css`
            margin: 0 auto;

            @media screen and (min-width: 991px) {
              max-width: var(--container-width);
            }
          `};

    @media screen and (min-width: 991px) {
      &.alignfull {
        max-width: 100%;
      }
      &.alignwide {
        max-width: 96.8rem;
      }
    }
  }

  .alignwide .core {
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    ul,
    ol,
    table {
      max-width: 96.8rem;
    }
  }

  .alignfull .core {
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    ul,
    ol,
    table {
      max-width: 100%;

      @media screen and (min-width: 991px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  .eplus-wrapper:is(ul, ol) {
    margin: 1.6rem auto;
  }

  table {
    margin-left: auto;
    margin-right: auto;
  }

  .eplus-wrapper:is(ul, ol),
  table {
    max-width: var(--container-width);
  }

  @media screen and (min-width: 991px) {
    .eplus-wrapper:is(ul, ol),
    table {
      &.alignfull {
        max-width: 100%;
      }
      &.alignwide {
        max-width: 96.8rem;
      }
    }
  }

  ${({ isPage }) =>
    !isPage &&
    css`
      @media screen and (min-width: 768px) {
        .eplus-wrapper:is(ul, ol),
        table {
          max-width: 76.8rem;
        }
      }
    `}

  p.eplus-wrapper:not(.text-text) {
    ${({ isPage }) =>
      isPage &&
      css`
        color: ${theme.colors.neutral[6]};
      `}
  }
  .core {
    @media screen and (min-width: 991px) {
      max-width: 100%;
    }

    ${({ isPage }) =>
      !isPage
        ? css`
            max-width: calc(100% - var(--container-margins));
          `
        : css`
            max-width: 100%;
          `}

    margin-left: auto;
    margin-right: auto;

    .alignwide {
      max-width: 96.8rem;
      margin-left: auto;
      margin-right: auto;
    }

    .alignfull {
      max-width: 100%;
    }

    ${({ isPage }) =>
      !isPage &&
      css`
        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        ul,
        ol,
        table {
          max-width: 76.8rem;
          margin-left: auto;
          margin-right: auto;
        }
      `}

    ul,
    ol {
      padding-left: 2rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: 800;
    }

    h1 {
      font-size: 3.2rem;
    }

    h2 {
      font-size: 2.4rem;
    }

    h2,
    h3,
    h4,
    h5 {
      margin: 1.6rem auto;
    }

    h3 {
      font-size: 2rem;
    }

    h4 {
      font-size: 1.8rem;
    }

    h5 {
      font-size: 1.6rem;
    }

    p {
      line-height: 1.8;
      margin-bottom: 1.6rem;
    }

    li {
      margin-bottom: 1rem;

      &::marker {
        color: ${theme.colors.neutral[4]};
      }
    }

    figure figcaption {
      color: ${theme.colors.neutral[4]};
      font-size: 1.2rem;
      margin-top: 1.6rem;
    }

    blockquote {
      margin: 4rem auto;
      position: relative;
      font-size: 1.8rem;
      line-height: 3.24rem;
      padding: 0 !important;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 21.6rem;
        height: 21.6rem;
        background-image: url('/images/blockquote-pattern.svg');
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
      }

      &.is-style-large {
        font-size: 2.4rem;

        @media screen and (min-width: 1199px) {
          font-size: 3.2rem;
          line-height: 4rem;
        }
      }

      cite {
        max-width: 76.8rem;
        font-size: 1.8rem !important;
        display: block;
        margin: -4rem auto 0;
        padding: 0 1rem;
        text-align: right;
        font-weight: 800;
        font-style: normal;
      }

      p:empty {
        display: none;
      }

      p:not(:empty) {
        font-size: inherit !important;
        font-style: normal;
        line-height: inherit;
        position: relative;
        padding: 5.6rem 1rem;
        display: block;
        max-width: 98rem;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        margin-bottom: 0;

        em {
          font-style: inherit;
        }

        &::after {
          content: '\\201C';
          position: absolute;
          font-size: 12rem;
          height: 100%;
          display: flex;
          top: 4.5rem;

          @media screen and (min-width: 1199px) {
            left: -10%;
            align-items: center;
            top: 3.5rem;
            font-size: 14rem;
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 100%;
          max-width: 76.8rem;
          z-index: -1;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(211, 217, 222)' stroke-width='1' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        }
      }
    }
  }
`;

export default EditorWrapper;
