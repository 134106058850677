import clsx from 'clsx';
import React from 'react';
import { getColorClassFromGutenberg } from 'src/utils/gutenbergUtils';

export interface HeadingProps {
  title: string;
  level: string | number;
  innerHTML?: string;
  fontSize?: string;
  className?: string;
}

/**
 *
 * @param fontSize tailwind value
 * @returns
 */
const Heading: React.FunctionComponent<HeadingProps> = (props) => {
  const { title, level = 'h2', innerHTML, fontSize, className } = props;
  const HeadingTag = `${level}` as keyof JSX.IntrinsicElements;
  const html = getColorClassFromGutenberg(innerHTML || '');

  const classNames = clsx(fontSize ? `text-${fontSize}` : '', {
    [className || '']: className,
  });
  return (
    <div className={classNames}>
      {innerHTML ? (
        <div
          className={className}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      ) : (
        <HeadingTag
          className={classNames}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      )}
    </div>
  );
};

export default Heading;
