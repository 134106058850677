import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Container from '@components/containers/Container';
import CrossIcon from '../../../assets/images/icons/Cross.svg';
import clsx from 'clsx';
import Badge from '@components/views/Badge';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import Spinner from '@components/views/Spinner';
import SearchItem from '@components/views/SearchItem';
import SearchBadge from '@components/views/SearchBadge';
import { useDebounce } from 'use-debounce';
import { useSearchQuery } from 'src/services/api/queries/hooks/useSearchQuery';
import { useRouter } from 'next/router';
import { useOptionsQuery } from 'src/services/api/queries/hooks/useOptionsQuery';
import { useAppContext } from 'src/context/app.context';
import RecentSearchQueries from './RecentSearchQueries';
import { getSearchRouterParams } from 'src/utils/search';
import SmallHeading from '@components/views/SmallHeading';
import Button from '@components/views/Button';
import useKeyDown from 'src/hooks/useKeyDown';

export interface IBadge {
  id: number;
  name: string;
  leadingIcon?: string;
  subtype: string[];
}

const getBadgesIds = (badges: IBadge[]) => badges.map((badge) => badge.id);

const SearchContainer: React.FunctionComponent = () => {
  const { handleSearchHidden, isSearchVisible } = useAppContext();
  const [search, setSearch] = useState('');
  const [defaultSearchValue, setDefaultSearchValue] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [badges, setBadges] = useState<IBadge[]>([]);
  const options = useOptionsQuery();
  const router = useRouter();
  const escapeKeyDown = useKeyDown();
  const subtypes = useMemo(
    () => [...new Set(badges.map((badge) => badge.subtype).flat())],
    [badges],
  );

  const searchData = useSearchQuery(
    {
      search: debouncedSearch,
      per_page: 10,
      subtype: subtypes.length
        ? subtypes
        : options.data?.searchbar.allowed_post_types.map(
            (type) => type.post_type,
          ),
      _fields: ['id', 'subtype', 'url', 'title'],
    },
    { enabled: !!debouncedSearch },
  );

  const handleResetState = () => {
    setSearch('');
    setDefaultSearchValue('');
    setBadges([]);
  };

  const handleSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleRemoveBadge = (id: number) =>
    setBadges((prevState) => prevState.filter((badge) => badge.id !== id));

  const handleAddBadge = (badge: IBadge) =>
    setBadges((prevState) => [...prevState, badge]);

  const allowed_post_types = options.data?.searchbar?.allowed_post_types || [];
  const badgesData: IBadge[] = [
    {
      id: 1,
      name: 'Na całej stronie',
      leadingIcon: '/images/Bulb.svg',
      subtype: [],
    },
    ...allowed_post_types.map((postType, index) => {
      return {
        id: index + 2,
        name: postType.label,
        subtype: [postType.post_type],
        leadingIcon: postType.icon || '',
      };
    }),
  ];

  // set default value from url param
  useEffect(() => {
    const routerQuerySearchParam = router.query.search as string;
    if (routerQuerySearchParam) {
      setSearch((routerQuerySearchParam as string) || '');
      setDefaultSearchValue((routerQuerySearchParam as string) || '');
    }
  }, [router]);

  useEffect(() => {
    if (!escapeKeyDown || !isSearchVisible) return;

    handleSearchHidden();
  }, [escapeKeyDown, handleSearchHidden, isSearchVisible]);

  return (
    <form
      className={clsx(
        'fixed top-0 left-0 w-full h-screen z-50 transform transition-transform duration-300',
        {
          '-translate-y-full invisible': !isSearchVisible,
        },
      )}
      onSubmit={(e) => {
        e.preventDefault();
        if (search) {
          router.push(getSearchRouterParams({ search, subtypes })).then(() => {
            handleSearchHidden();
          });
        }
      }}
    >
      <div className="min-h-screen flex flex-col">
        <div className="bg-neutral-0 transition-all">
          <Container className="py-40 flex flex-col-reverse gap-16 lg:items-center lg:flex-row">
            {badges && badges.length > 0 && (
              <div className="flex gap-12 flex-wrap flex-shrink-0 lg:max-w-[260px]">
                {badges.map((badge) => (
                  <div
                    key={`badge-${badge.id}`}
                    className="flex-shrink-0 inline-flex"
                  >
                    <Badge
                      onRemove={() => handleRemoveBadge(badge.id)}
                      {...omit(badge, ['name', 'id'])}
                    >
                      {badge.name}
                    </Badge>
                  </div>
                ))}
              </div>
            )}

            <input
              type="text"
              placeholder="Czego szukasz?"
              className="placeholder:text-2xl lg:placeholder:text-4xl placeholder:text-neutral-3 text-2xl lg:text-4xl block w-full text-primary appearance-none focus:outline-none"
              onChange={handleSearchChange}
              defaultValue={defaultSearchValue}
              value={search}
            />
            {(defaultSearchValue || search || badges.length > 0) && (
              <div className="lg:ml-8 mr-32">
                <Button
                  onClick={() => handleResetState()}
                  variant="LINK"
                  noPadding
                  type="button"
                >
                  Wyczyść
                </Button>
              </div>
            )}

            <button
              type="button"
              onClick={handleSearchHidden}
              className="w-56 h-56 border rounded-full text-neutral-9 hover:text-neutral-4 flex justify-center items-center hover:bg-neutral-2 hover:border-neutral-2 transition-colors ml-auto flex-shrink-0"
            >
              <CrossIcon fill="currentColor" />
            </button>
          </Container>
          <div className="h-1 w-full bg-neutral-2" />
          <Container className="py-32">
            <div className="mb-32">
              <div className="mb-12 ">
                <SmallHeading label="Szukam" />
              </div>
              <div className="flex gap-12 flex-wrap">
                {badgesData
                  .filter((badge) => badge.name)
                  .map((badge) => (
                    <SearchBadge
                      key={`badge-available-${badge.id}`}
                      badge={badge}
                      handleAddBadge={() => handleAddBadge(badge)}
                      handleRemoveBadge={() => handleRemoveBadge(badge.id)}
                      isActive={getBadgesIds(badges).includes(badge.id)}
                    />
                  ))}
              </div>
            </div>
            {!searchData?.data?.data && !search && !searchData.isLoading && (
              <RecentSearchQueries onClickHandler={handleSearchHidden} />
            )}

            {searchData.isLoading ? (
              <Spinner />
            ) : (
              (search || defaultSearchValue || badges.length > 0) &&
              searchData?.data?.data &&
              searchData?.data?.data.length > 0 && (
                <div>
                  <ul>
                    {orderBy(searchData?.data?.data, ['subtype'], ['desc']).map(
                      (result) => (
                        <SearchItem
                          key={`${result.title}-${result.id}`}
                          result={result}
                          search={search}
                        />
                      ),
                    )}
                  </ul>
                </div>
              )
            )}
          </Container>
        </div>
        <button
          type="button"
          className={clsx(
            'flex-1 bg-primary opacity-90 flex justify-center items-center transition-opacity delay-200',
            { 'opacity-0': !isSearchVisible },
            { 'hover:opacity-80': isSearchVisible },
          )}
          onClick={handleSearchHidden}
        >
          <div className="text-neutral-0 underline">Wróć do strony</div>
        </button>
      </div>
    </form>
  );
};
export default SearchContainer;
