import DropdownButton from '@components/views/Dropdown';
import { DropdownItem } from '@components/views/Dropdown/DropdownItem';
import React from 'react';
import GlobalIcon from '../../../assets/images/icons/global.svg';

// TODO: Get data from API
const items = [
  {
    onClickHandler: () => null,
    label: 'Polski',
  },
];

const LanguageSwitch = () => {
  return (
    <DropdownButton
      button={{
        icon: {
          iconComponent: <GlobalIcon fill="fill-neutral-9" />,
          isReverse: true,
        },
        label: 'Zmień język (PL)',
        variant: 'PLAIN',
      }}
      items={items}
      ItemComponent={DropdownItem}
    />
  );
};

export default LanguageSwitch;
