import { useEffect, useState } from 'react';

const useWindowHref = () => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (window.location.href) {
      setUrl(window.location.href);
    }
  }, []);

  return { url };
};

export default useWindowHref;
