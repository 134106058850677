import { DropdownHeading, DropdownHeadingProps } from './DropdownHeading';

interface DropdownContentProps<T> {
  items: Array<T>;
  renderInner: (component: T, index?: number) => React.ReactNode;
  heading?: DropdownHeadingProps['heading'];
}

export const DropdownContent = <T,>({
  items,
  renderInner,
  heading,
}: DropdownContentProps<T>) => {
  return (
    <div className="bg-neutral-0 py-4 border border-neutral-2 divide-y divide-neutral-2 focus:outline-none">
      {heading && <DropdownHeading>{heading}</DropdownHeading>}
      {items && items.length > 0 && (
        <div>{items.map((item) => renderInner(item))}</div>
      )}
    </div>
  );
};
