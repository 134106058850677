import clsx from 'clsx';
import React from 'react';
import Image from 'next/image';
import RemoveIcon from '../../../assets/images/icons/Remove.svg';

export interface BadgeProps {
  variant?: 'default' | 'primary' | 'neutral';
  leadingIcon?: string;
  trailingIcon?: string;
  onRemove?: () => void;
}

const Badge: React.FunctionComponent<BadgeProps> = ({
  variant,
  leadingIcon,
  trailingIcon,
  children,
  onRemove,
}) => {
  const IconImage = ({ img }: { img: string }) => (
    <Image
      width="14"
      height="14"
      src={img}
      alt=""
      className={clsx({ 'filter grayscale invert': variant === 'primary' })}
    />
  );

  return (
    <div
      className={clsx(
        'py-4 px-8 font-medium text-sm rounded-4 transition-all inline-flex items-center hover:opacity-90 transform hover:scale-95  duration-75',
        {
          'text-neutral-9 bg-neutral-2': variant === 'default',
          'text-neutral-0 bg-neutral-9': variant === 'primary',
          'text-neutral-5 bg-neutral-2': variant === 'neutral',
        },
      )}
    >
      {leadingIcon && (
        <span className="mr-6 flex items-center">
          <IconImage img={leadingIcon} />
        </span>
      )}
      {children}
      {!!onRemove && (
        <button
          className="ml-6 flex items-center"
          onClick={onRemove}
          type="button"
        >
          <RemoveIcon />
        </button>
      )}
      {trailingIcon && (
        <span className="ml-6 flex items-center">
          <IconImage img={trailingIcon} />
        </span>
      )}
    </div>
  );
};

Badge.defaultProps = {
  variant: 'default',
};

export default Badge;
