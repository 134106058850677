import { css } from 'styled-components';
import { theme } from '@styles/theme';

interface IFontSize {
  [key: string]: (string | string[])[];
}

const listItemStyles = css`
  margin: 1rem 0;
  display: table;
  position: relative;
`;

const unorderedListStyles = css`
  list-style: disc;

  li {
    ${listItemStyles};
  }

  li::before {
    content: '';
    display: table-cell;
    width: 2.2rem;
  }

  li::after {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 100vh;
    background-color: ${theme.colors.neutral[5]};
    position: absolute;
    top: 1.5rem;
    left: 0;
    transform: translate(50%, -50%);
  }
`;

const orderedListStyles = css`
  counter-reset: section var(--start-value, 0);
  list-style-type: none;

  ol {
    counter-reset: section var(--start-value, 0);
    list-style-type: none;
  }

  li {
    ${listItemStyles};
  }

  li::before {
    counter-increment: section;
    content: counters(section, '.') ' ';
    padding-right: 1.2rem;
    color: ${theme.colors.neutral[5]};
    font-size: 1.8rem;
    display: table-cell;
  }
  > li::before {
    content: counters(section, '.') '. ';
  }
`;

export const defaultStyles = css`
  .has-primary:is(mark, ul, ol) {
    color: ${theme.colors.primary.DEFAULT};
  }

  .has-denotive-0-color:is(mark, ul, ol) {
    color: ${theme.colors.denotive.success[900]};
  }

  .has-denotive-1-color:is(mark, ul, ol) {
    color: ${theme.colors.denotive.success[50]};
  }

  .has-denotive-2-color:is(mark, ul, ol) {
    color: ${theme.colors.denotive.error[50]};
  }

  .has-denotive-3-color:is(mark, ul, ol) {
    color: ${theme.colors.denotive.warning[50]};
  }

  .has-denotive-4-color:is(mark, ul, ol) {
    color: ${theme.colors.denotive.danger[900]};
  }

  ${() => {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(
      (item) => `.has-neutral-${item}-color:is(mark, ul, ol) {
      color: ${theme.colors.neutral[item]};
    }`,
    );
  }}

  .lg-outer .lg-thumb-item.active,
  .lg-outer .lg-thumb-item:hover {
    border-color: ${theme.colors.primary.DEFAULT};
  }

  .ep-custom-list {
    ul {
      ${unorderedListStyles}
    }

    ol {
      ${orderedListStyles};
    }
  }

  ul,
  ol {
    &.eplus-wrapper {
      a {
        text-decoration: underline;
        text-underline-offset: 4px;

        &:hover {
          color: ${({ theme }) => theme.colors.neutral[5]};
        }
      }
    }
  }

  ul.eplus-wrapper {
    ${unorderedListStyles};
  }

  .core {
    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
      a {
        transition: color 0.2s;
        text-decoration: underline;

        &:hover {
          color: ${theme.colors.neutral[8]};
        }
      }
    }

    ${({ theme }) => {
      const entries = Object.entries(theme.fontSize as IFontSize);

      return entries.map(
        ([key, value]) => css`
          .has-${key.match(/[a-z]+|\d+/gi)?.join('-')}-font-size.eplus-wrapper {
            font-size: ${value[0]};
          }
        `,
      );
    }};
  }

  ol.eplus-wrapper {
    ${orderedListStyles};
  }
`;
