import Container from '@components/containers/Container';
import React, { useContext, useRef } from 'react';
import NavLogo from '@components/containers/NavLogo';
import Burger from '@components/views/Menu/Burger';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { MenuContext } from '@components/views/Menu/context/Menu.context';
import { IImage } from 'src/services/api/types/IImage';

const NavbarMobile = ({ productLogo }: { productLogo?: IImage }) => {
  const xlClasses = `xl:hidden xl:invisible`;
  const menuRef = useRef(null);
  const { closeMenu } = useContext(MenuContext);

  useOnClickOutside(menuRef, closeMenu);

  return (
    <header
      className={`visible block sticky top-0 bg-neutral-0 w-full z-[60] ${xlClasses}`}
      ref={menuRef}
    >
      <div className="relative flex items-center justify-between w-full py-24">
        <Container isStatic>
          <div className="flex items-center justify-between w-full">
            <div className="mr-8 lg:mr-32">
              <NavLogo productLogo={productLogo} />
            </div>

            <div className="flex xl:hidden items-center justify-end w-full">
              {/* //TODO: search and divider icon here */}
              <Burger />
            </div>
          </div>
        </Container>
        <div className="h-1 bg-neutral-1 absolute w-full bottom-0" />
      </div>
    </header>
  );
};

export default NavbarMobile;
