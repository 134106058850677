import Blocks from '@components/containers/Blocks';
import Container from '@components/containers/Container';
import clsx from 'clsx';
import styled from 'styled-components';
import { IMenu } from 'src/services/api/types/IMenu';
import { MenuContext } from '../context/Menu.context';
import { mediaQueries } from '@styles/theme';
import React, { useContext, useMemo } from 'react';
import ChevronRightIcon from '../../../../assets/images/icons/chevron-right.svg';

const MegaMenuStyled = styled.div`
  ${mediaQueries.greaterThan('xl')`
  max-height: calc(100vh - 7.2rem);
 `}
`;

const MegaMenu = ({ menuItems }: { menuItems: IMenu[] }) => {
  const { isOpen, openedMenu, closeMegaMenuHandler } = useContext(MenuContext);
  const openedMenuData = useMemo(() => {
    if (!openedMenu) return null;
    return menuItems.find((menuItem) => menuItem.mega_menu_id === openedMenu);
  }, [openedMenu]);

  const isVisible = openedMenu === openedMenuData?.mega_menu_id;

  const xlClasses = `bottom-0 left-0`;

  return (
    <MegaMenuStyled
      className={clsx(
        `bg-neutral-0 mb-8 lg:my-24 w-full z-10 xl:overflow-auto ${xlClasses}`,
        {
          'hidden invisible': !isVisible,
          'block visible': isVisible,
          invisible: !isOpen && isVisible,
        },
      )}
    >
      <Container
        hasImportantMaxWidth
        className={clsx({
          'opacity-0 hidden invisible xl:block': !isVisible,
          'opacity-100 block visible transition-all duration-[1000ms]':
            isVisible,
          'opacity-0 invisible xl:opacity-100 xl:visible ':
            !isOpen && isVisible,
        })}
      >
        {openedMenuData && openedMenuData.mega_menu_blocks && (
          <>
            <button
              className="inline-flex items-center text-lg p-8 -ml-8 mb-34 inline-block transition-opacity hover:opacity-70"
              onClick={() => closeMegaMenuHandler(undefined)}
            >
              <ChevronRightIcon
                fill="fill-neutral-9"
                className="mr-20 transform rotate-180"
                style={{ width: 8, height: 12 }}
              />
              Cofnij
            </button>
            <Blocks blocks={openedMenuData.mega_menu_blocks} />
          </>
        )}
      </Container>
    </MegaMenuStyled>
  );
};

export default MegaMenu;
