import useWindowHref from 'src/hooks/useWindowHref';

const useIsExternalUrl = (url: string) => {
  const { url: windowUrl } = useWindowHref();

  if (windowUrl) {
    const { host: windowHost } = new URL(windowUrl);

    try {
      new URL(url);
    } catch (error) {
      return false;
    }

    return !url.includes(windowHost);
  }

  return false;
};

export default useIsExternalUrl;
