import { IMenu } from 'src/services/api/types/IMenu';
import { MenuActiveItemHandlerType } from 'src/components/views/Menu/hooks/useOpenMenu';

export const handleOpenMenu = (
  targetId: IMenu['mega_menu_id'],
  openedId: IMenu['mega_menu_id'],
  openHandler: MenuActiveItemHandlerType,
  closeHandler?: MenuActiveItemHandlerType,
) => {
  if (targetId === openedId && closeHandler) {
    closeHandler(undefined);
  } else {
    openHandler(targetId);
  }
};
